import { useContext } from "react";
import { buildClasses } from "../utils/buildClasses";
import { DefaultTransactionColumnValue } from "./DefaultTransactionColumnValue";
import { TransactionColumn } from "./TransactionColumn";
import { TransactionColumnSizer } from "./TransactionColumnSizer";
import { TransactionRowContext } from "./TransactionRow";

export function TransactionColumnValueDescription({
  description,
}: {
  description: string | null;
}): JSX.Element {
  const { ruleForFieldHighlighting } = useContext(TransactionRowContext);

  return (
    <TransactionColumnSizer column={TransactionColumn.DESCRIPTION}>
      <DefaultTransactionColumnValue
        ruleHighlight={
          ruleForFieldHighlighting.get !== null &&
          (ruleForFieldHighlighting.get.criteria.description !== null ||
            ruleForFieldHighlighting.get.criteria.partial_description !== null)
        }
      >
        <div
          className={buildClasses(
            "w-100",
            "whitespace-nowrap",
            "overflow-hidden",
            "overflow-ellipsis"
          )}
        >
          {description}
        </div>
      </DefaultTransactionColumnValue>
    </TransactionColumnSizer>
  );
}
