import { FirestoreDocCategoryGroup, FirestoreDocUser } from "breadcommon";
import { createContext, useEffect, useState } from "react";
import { firestoreCategoryGroupsSubscribe } from "./firestoreIo";

export const CategoryGroupsContext = createContext<
  Map<string, FirestoreDocCategoryGroup>
>(new Map());

export function CategoryGroupsContextProvider(props: {
  children: JSX.Element;
  user: FirestoreDocUser;
  markInitialLoadComplete: () => void;
}): JSX.Element {
  const [categoryGroups, setCategoryGroups] = useState<
    Map<string, FirestoreDocCategoryGroup>
  >(new Map());

  useEffect(() => {
    return firestoreCategoryGroupsSubscribe(
      props.user.uid,
      setCategoryGroups,
      props.markInitialLoadComplete
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.uid]);

  return (
    <CategoryGroupsContext.Provider value={categoryGroups}>
      {props.children}
    </CategoryGroupsContext.Provider>
  );
}
