import { buildClasses } from "../utils/buildClasses";
import { RyeIcon } from "./RyeIcon";

export function RyeButton({
  text,
  icon,
  onClick = () => {},
  variant = "outlined",
  vibe = "ordinary",
  size = "md",
  shape = "rounded-rect",
  shadow = "none",
  disabled = false,
  onlyShowOnParentHover = false, // note you need to attach the "group" class to the button's parent to use
}: {
  text?: string;
  icon?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  size?: "sm" | "md" | "lg";
  shape?: "rounded-rect" | "fully-round";
  shadow?: "none" | "sm" | "md" | "lg";
  variant?: "filled" | "outlined" | "transparent";
  vibe?: "subdued" | "ordinary" | "primary" | "danger";
  disabled?: boolean;
  onlyShowOnParentHover?: boolean;
}): JSX.Element {
  return (
    <button
      onClick={(e) => onClick(e)}
      className={buildClasses(
        {
          if: onlyShowOnParentHover,
          then: buildClasses("invisible", "group-hover:visible"),
        },
        getButtonClassesForSize(size, text === undefined),
        getButtonClassForShape(shape),
        getButtonClassForShadow(shadow),
        getButtonClassesForVariantAndVibe(variant, vibe),
        "flex-shrink-0",
        "block" // we do this because of https://stackoverflow.com/questions/40385945/parent-element-with-height-auto-is-higher-than-highest-child
      )}
      disabled={disabled}
    >
      <ButtonContent text={text} icon={icon} size={size} />
    </button>
  );
}

function ButtonContent({
  text,
  icon,
  size,
}: {
  text?: string;
  icon?: string;
  size: "sm" | "md" | "lg";
}): JSX.Element {
  if (icon === undefined && text === undefined) {
    return <span></span>;
  }
  if (icon === undefined) {
    return <span className={getCenteringClasses()}>{text}</span>;
  }
  if (text === undefined) {
    return (
      <div className={getCenteringClasses()}>
        <RyeIcon name={icon}></RyeIcon>
      </div>
    );
  }
  return (
    <div className={getCenteringClasses()}>
      <RyeIcon
        name={icon}
        size={size}
        className={getIconClassesWhenWithText(size)}
      ></RyeIcon>
      <span>{text}</span>
    </div>
  );
}

function getIconClassesWhenWithText(size: "sm" | "md" | "lg"): string {
  // Negative margin corrects for the extra padding that always exists around icons
  switch (size) {
    case "sm":
      return buildClasses("-ml-1", "mr-1");
    case "md":
      return buildClasses("-ml-1", "mr-1.5");
    case "lg":
      return buildClasses("-ml-1", "mr-2");
    default:
      return "";
  }
}

function getCenteringClasses() {
  return buildClasses("flex", "items-center", "justify-center");
}

function getButtonClassesForSize(
  size: "sm" | "md" | "lg",
  makeSquare: boolean
): string {
  switch (size) {
    case "sm":
      return buildClasses(
        { if: makeSquare, then: "w-7", else: "px-2.5" },
        "h-7",
        "text-sm"
      );
    case "md":
      return buildClasses(
        { if: makeSquare, then: "w-9", else: "px-3" },
        "h-9",
        "text-md"
      );
    case "lg":
      return buildClasses(
        { if: makeSquare, then: "w-9", else: "px-3.5" },
        "h-11",
        "text-lg"
      );
    default:
      return "";
  }
}

function getButtonClassForShape(shape: "rounded-rect" | "fully-round"): string {
  switch (shape) {
    case "rounded-rect":
      return "rounded-md";
    case "fully-round":
      return "rounded-full";
    default:
      return "";
  }
}

function getButtonClassForShadow(shadow: "none" | "sm" | "md" | "lg"): string {
  switch (shadow) {
    case "none":
      return "shadow-none";
    case "sm":
      return "shadow-sm";
    case "md":
      return "shadow-md";
    case "lg":
      return "shadow-lg";
    default:
      return "";
  }
}

function getButtonClassesForVariantAndVibe(
  variant: "filled" | "outlined" | "transparent",
  vibe: "subdued" | "ordinary" | "primary" | "danger"
): string {
  if (variant === "filled") {
    switch (vibe) {
      case "subdued":
        return buildClasses(
          "bg-rock-200",
          "text-gray-600",
          "font-thin",
          "enabled:hover:bg-rock-300"
        );
      case "ordinary":
        return buildClasses(
          "bg-rock-400",
          "text-white",
          "enabled:hover:bg-rock-500"
        );
      case "primary":
        return buildClasses(
          "bg-purple",
          "text-white",
          "enabled:hover:bg-purple-600"
        );
      case "danger":
        return buildClasses("bg-red", "text-white", "enabled:hover:bg-red-600");
      default:
        return "";
    }
  }
  if (variant === "outlined") {
    switch (vibe) {
      case "subdued":
        return buildClasses(
          "border",
          "border-rock-200",
          "text-gray-500",
          "font-thin",
          "enabled:hover:bg-rock-100",
          "enabled:hover:border-rock-300",
          "enabled:hover:text-gray-600"
        );
      case "ordinary":
        return buildClasses(
          "border",
          "border-rock-300",
          "bg-rock-100",
          "text-gray-600",
          "enabled:hover:bg-rock-200",
          "enabled:hover:border-rock-400",
          "enabled:hover:text-gray-700"
        );
      case "primary":
        return buildClasses(
          "border",
          "border-purple",
          "bg-purple-100",
          "text-purple",
          "enabled:hover:bg-purple-200",
          "enabled:hover:border-purple-600",
          "enabled:hover:text-purple-600"
        );
      case "danger":
        return buildClasses(
          "border",
          "border-red",
          "bg-red-100",
          "text-red",
          "enabled:hover:bg-red-200",
          "enabled:hover:border-red-600",
          "enabled:hover:text-red-600"
        );
      default:
        return "";
    }
  }
  // variant === "transparent"
  switch (vibe) {
    case "subdued":
      return buildClasses(
        "text-gray-500",
        "font-thin",
        "enabled:hover:bg-rock-200"
      );
    case "ordinary":
      return buildClasses("text-gray-700", "enabled:hover:bg-rock-200");
    case "primary":
      return buildClasses("text-purple", "enabled:hover:bg-purple-100");
    case "danger":
      return buildClasses("text-red", "enabled:hover:bg-red-100");
    default:
      return "";
  }
}
