import { Fragment } from "react";
import { buildClasses } from "../utils/buildClasses";
import { TransactionColumn } from "./TransactionColumn";
import { SortIcon } from "./TransactionHeaderSortIcon";
import { TRANSACTION_CELL_PADDING_X } from "./TransactionColumnSizer";

// Helper for constructing the "default" column header component.
export function DefaultTransactionColumnHeader({
  column,
  text,
  align = "LEFT",
}: {
  column: TransactionColumn;
  text: string;
  align?: "LEFT" | "RIGHT";
}): JSX.Element {
  return (
    <div
      className={buildClasses(
        {
          if: align === "RIGHT",
          then: "justify-end",
          else: "justify-start",
        },
        "w-full",
        "flex",
        "items-center",
        TRANSACTION_CELL_PADDING_X,
        "group" // https://tailwindcss.com/docs/hover-focus-and-other-states#styling-based-on-parent-state
      )}
    >
      {align === "RIGHT" ? (
        <Fragment>
          <SortIcon column={column} />
          <div className={buildClasses("w-3")} />
        </Fragment>
      ) : null}
      <div>{text}</div>
      {align === "LEFT" ? (
        <Fragment>
          <div className={buildClasses("w-3")} />
          <SortIcon column={column} />
        </Fragment>
      ) : null}
    </div>
  );
}
