import { buildClasses } from "../utils/buildClasses";
import {
  RyeMainContentCard,
  STANDARD_MAIN_CONTENT_CARD_PADDING_X,
  STANDARD_MAIN_CONTENT_CARD_PADDING_Y,
} from "./RyeMainContentCard";

export function RyeMainContentHeader({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  return (
    <RyeMainContentCard
      fitContentHeight={true}
      roundedBl={false}
      roundedBr={false}
      flexGrow={false}
      flexShrink={false}
    >
      <div
        className={buildClasses(
          STANDARD_MAIN_CONTENT_CARD_PADDING_X,
          STANDARD_MAIN_CONTENT_CARD_PADDING_Y,
          "w-full",
          "flex",
          "items-center"
        )}
      >
        {children}
      </div>
    </RyeMainContentCard>
  );
}
