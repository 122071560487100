import { Fragment, useContext, useState } from "react";
import { RyeButton } from "../rye/RyeButton";
import {
  RyeMainContentCard,
  STANDARD_MAIN_CONTENT_CARD_PADDING_X,
  STANDARD_MAIN_CONTENT_CARD_PADDING_Y,
} from "../rye/RyeMainContentCard";
import { RyeMainContentHeader } from "../rye/RyeMainContentHeader";
import { RyeMainContentSpacer } from "../rye/RyeMainContentSpacer";
import { RyeMainContentTitle } from "../rye/RyeMainContentTitle";
import { buildClasses } from "../utils/buildClasses";
import { CategoryGroupsContext } from "../firebaseio/CategoryGroupsContext";
import { CategoriesContext } from "../firebaseio/CategoriesContext";
import {
  ItemsGroup,
  RyeDraggableGroupedList,
} from "../rye/RyeDraggableGroupedList";
import {
  firestoreCreateCategoryGroup,
  firestoreUpdateCategoriesOrder,
  firestoreUpdateCategoryGroupsOrder,
} from "../firebaseio/firestoreIo";
import { UserContext } from "../firebaseio/UserContext";
import dayjs from "dayjs";
import { CategoryItem } from "./CategoryItem";
import { CategoryGroupItem } from "./CategoryGroupItem";
import { createContextWithSetter } from "../utils/ContextUtils";

export const FocusCategoryIndexContext = createContextWithSetter<{
  groupId: string;
  categoryIndex: number;
} | null>(null);
export const FocusCategoryGroupIndexContext = createContextWithSetter<
  number | null
>(null);

export function CategoriesScreen(): JSX.Element {
  const [focusCategoryIndex, setFocusCategoryIndex] = useState<{
    groupId: string;
    categoryIndex: number;
  } | null>(null);
  const [focusCategoryGroupIndex, setFocusCategoryGroupIndex] = useState<
    number | null
  >(null);
  const user = useContext(UserContext);
  const categoryGroups = useContext(CategoryGroupsContext);
  const categories = useContext(CategoriesContext);

  const groupedCategoriesBuilder: Map<string, ItemsGroup> = new Map();
  Array.from(categoryGroups.values())
    .sort((a, b) => a.order_position - b.order_position)
    .forEach((categoryGroup) => {
      groupedCategoriesBuilder.set(categoryGroup.id, {
        id: categoryGroup.id,
        value: <CategoryGroupItem categoryGroup={categoryGroup} />,
        items: [],
      });
    });
  Array.from(categories.values())
    .sort((a, b) => a.order_position - b.order_position)
    .forEach((category) => {
      groupedCategoriesBuilder.get(category.category_group_id)!.items.push({
        id: category.id,
        value: <CategoryItem category={category} />,
      });
    });
  const groupedCategories: ItemsGroup[] = Array.from(
    groupedCategoriesBuilder.values()
  );

  return (
    <FocusCategoryGroupIndexContext.Provider
      value={[focusCategoryGroupIndex, setFocusCategoryGroupIndex]}
    >
      <FocusCategoryIndexContext.Provider
        value={[focusCategoryIndex, setFocusCategoryIndex]}
      >
        <div
          className={buildClasses(
            "w-full",
            "h-full",
            "flex",
            "flex-col",
            "flex-grow",
            "flex-shrink",
            "min-w-1"
          )}
        >
          <RyeMainContentHeader>
            <Fragment>
              <RyeButton
                text="New Category Group"
                icon="add"
                size="sm"
                variant="outlined"
                vibe="subdued"
                onClick={() => {
                  firestoreCreateCategoryGroup(user.uid, {
                    name: "New Category Group",
                    created_timestamp_secs: dayjs().unix(),
                    order_position: categoryGroups.size,
                  });
                  setFocusCategoryGroupIndex(categoryGroups.size);
                }}
              />
            </Fragment>
          </RyeMainContentHeader>
          <RyeMainContentSpacer direction={"h"} />
          <RyeMainContentCard roundedTl={false} roundedTr={false}>
            <div
              className={buildClasses(
                STANDARD_MAIN_CONTENT_CARD_PADDING_X,
                STANDARD_MAIN_CONTENT_CARD_PADDING_Y,
                "min-w-[840px]"
              )}
            >
              <div className={buildClasses("px-4", "pt-4")}>
                <RyeMainContentTitle title={"Categories"} />
              </div>
              <div>
                <RyeDraggableGroupedList
                  inputGroupedItems={groupedCategories}
                  onGroupOrderUpdate={(newIdList: string[]) =>
                    firestoreUpdateCategoryGroupsOrder(user.uid, newIdList)
                  }
                  onItemOrderUpdate={(newGroupedItems: ItemsGroup[]) =>
                    firestoreUpdateCategoriesOrder(user.uid, newGroupedItems)
                  }
                  collapsedItemSpacerHeight={3}
                  collapsedGroupSpacerHeight={15}
                  backgroundColor="transparent"
                />
              </div>
              <div className="h-96" />
            </div>
          </RyeMainContentCard>
        </div>
      </FocusCategoryIndexContext.Provider>
    </FocusCategoryGroupIndexContext.Provider>
  );
}
