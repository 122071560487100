import { buildClasses } from "../utils/buildClasses";
import { TransactionColumn } from "./TransactionColumn";

// Primary responsibility is keeping the width of the column correct for
// column headers and values.
export function TransactionColumnSizer({
  column,
  children,
}: {
  column: TransactionColumn;
  children?: JSX.Element;
}): JSX.Element {
  return (
    <div
      className={buildClasses(
        {
          switch: column,
          cases: new Map([
            [
              TransactionColumn.DATE,
              buildClasses("w-32", "flex-grow-0", "flex-shrink-0"),
            ],
            [
              TransactionColumn.ACCOUNT,
              buildClasses("w-36", "flex-grow-[0.4]", "flex-shrink-[0.4]"),
            ],
            [
              TransactionColumn.ORIGIN,
              buildClasses("w-24", "flex-grow-[0.4]", "flex-shrink-[0.4]"),
            ],
            [
              TransactionColumn.DESCRIPTION,
              buildClasses("w-36", "flex-grow-[0.6]", "flex-shrink-[0.6]"),
            ],
            [
              TransactionColumn.CATEGORY,
              buildClasses("w-36", "flex-grow-[0.4]", "flex-shrink-[0.4]"),
            ],
            [
              TransactionColumn.RULE,
              buildClasses("w-8", "flex-grow-0", "flex-shrink-0"),
            ],
            [
              TransactionColumn.REVIEWED,
              buildClasses("w-8", "flex-grow-0", "flex-shrink-0"),
            ],
            [
              TransactionColumn.AMOUNT,
              buildClasses("w-36", "flex-grow-[0.1]", "flex-shrink-[0.1]"),
            ],
          ]),
        },
        "flex",
        "items-center",
        "h-full"
      )}
    >
      {children}
    </div>
  );
}

export const TRANSACTION_CELL_PADDING_X = "px-3";
