import styles from "./AddAccountModal.module.scss";
import buttonStyles from "../common/Buttons.module.scss";
import layoutStyles from "../utils/Layout.module.scss";
import {
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptions,
  usePlaidLink,
} from "react-plaid-link";
import { Icon } from "../Icon";
import { HorizontalSpacer, VerticalSpacer } from "../utils/Utils";
import Modal from "react-modal";
import { useContext, useEffect, useRef, useState } from "react";
import { lineWobble } from "ldrs";
import { DatePicker } from "@mui/x-date-pickers";
import { AccountStatuses, BreadAccount } from "breadcommon";
import {
  firestoreDeleteAccountById,
  firestoreUpdateAccount,
} from "../firebaseio/firestoreIo";
import dayjs from "dayjs";
import { UserContext } from "../firebaseio/UserContext";
import { InstitutionsContext } from "../firebaseio/InstitutionsContext";
import { AccountsContext } from "../firebaseio/AccountsContext";
import {
  exchangePublicToken,
  getPlaidLinkToken,
  removeUnusedItemsAndInstitutions,
  syncTransactions,
} from "../firebaseio/firebaseFunctions";
import { WriteBatch } from "firebase/firestore";

// https://uiball.com/ldrs/ are web components that must be registered before
// use
lineWobble.register();

function CandidateAccountRow(props: {
  account: BreadAccount;
  accountUpdatePromises: React.MutableRefObject<Promise<void | WriteBatch>[]>;
}): JSX.Element {
  const [isChecked, setIsChecked] = useState<boolean>(true);
  const institutions = useContext(InstitutionsContext);
  const user = useContext(UserContext);

  const horizontalSepAmt = 20;

  let institution = null;
  if (
    props.account.institution_id !== null &&
    institutions.has(props.account.institution_id)
  ) {
    institution = institutions.get(props.account.institution_id);
  }

  return (
    <div className={styles.candiateAccountContainer}>
      {institution !== null &&
      institution !== undefined &&
      institution.logo !== null ? (
        <img
          src={`data:image/png;base64, ${institution?.logo}`}
          alt="institution_logo"
          className={`${styles.candiateAccountInstitutionLogo} ${
            isChecked ? "" : styles.mutedLogo
          }`}
        ></img>
      ) : (
        <div
          className={`${styles.candiateAccountInstitutionLogo} ${
            styles.candiateAccountInstitutionLogoPlaceholder
          } ${isChecked ? "" : styles.mutedLogo}`}
          style={{
            color:
              institution !== null &&
              institution !== undefined &&
              institution.primary_color !== null
                ? institution.primary_color
                : "#6b6f75",
          }}
        >
          <Icon name={"account_balance"} size={25} fill={true}></Icon>
        </div>
      )}
      <HorizontalSpacer width={horizontalSepAmt}></HorizontalSpacer>
      <div
        className={`${styles.candiateAccountInstitutionName} ${
          isChecked ? "" : styles.mutedText
        }`}
      >
        {institution?.name}
      </div>
      <HorizontalSpacer width={horizontalSepAmt}></HorizontalSpacer>
      <div
        className={`${styles.candiateAccountAccountName} ${
          isChecked ? "" : styles.mutedText
        }`}
      >
        {props.account.name}
      </div>
      <HorizontalSpacer width={horizontalSepAmt}></HorizontalSpacer>
      <div
        className={`${styles.candiateAccountAccountMask} ${
          isChecked ? "" : styles.mutedText
        }`}
      >
        {"..." + props.account.mask}
      </div>
      <HorizontalSpacer width={horizontalSepAmt}></HorizontalSpacer>
      <div
        className={`${styles.candiateAccountAccountType} ${
          isChecked ? "" : styles.mutedText
        }`}
      >
        {props.account.type}
      </div>
      <HorizontalSpacer width={horizontalSepAmt}></HorizontalSpacer>
      <div className={styles.candiateAccountStartDateContainer}>
        <DatePicker
          defaultValue={props.account.sync_start_date}
          onChange={(newValue) => {
            const p = firestoreUpdateAccount(user.uid, props.account.id, {
              manual_data: {
                sync_start_timestamp_secs: newValue?.isValid()
                  ? newValue.unix()
                  : dayjs().subtract(1, "month").unix(),
              },
            });
            props.accountUpdatePromises.current.push(p);
          }}
          label="Sync start date"
          disabled={!isChecked}
          format="MM/DD/YY"
          slotProps={{ textField: { size: "small" } }}
        />
      </div>
      <HorizontalSpacer width={horizontalSepAmt}></HorizontalSpacer>
      <div
        className={`${styles.candiateAccountCheckBox} ${
          isChecked ? styles.candcandiateAccountCheckBoxChecked : ""
        }`}
        onClick={() => {
          if (isChecked) {
            const p = firestoreUpdateAccount(user.uid, props.account.id, {
              system_data: { status: AccountStatuses.NEW_UNSELECTED },
            });
            props.accountUpdatePromises.current.push(p);
          } else {
            const p = firestoreUpdateAccount(user.uid, props.account.id, {
              system_data: { status: AccountStatuses.NEW_SELECTED },
            });
            props.accountUpdatePromises.current.push(p);
          }
          setIsChecked(!isChecked);
        }}
      >
        <Icon name={"done"} size={23}></Icon>
      </div>
    </div>
  );
}

function LinkingWithPlaid(props: {
  public_token: string;
  closeModal: () => void;
}): JSX.Element {
  const [accountInitDone, setAccountInitDone] = useState<boolean>(false);
  const [cancelling, setCancelling] = useState<boolean>(false);
  const [syncing, setSyncing] = useState<boolean>(false);
  const accounts = useContext(AccountsContext);
  const user = useContext(UserContext);
  const accountUpdatePromises = useRef<Promise<void>[]>([]);

  useEffect(() => {
    async function initAccountsFromPlaidLink() {
      await exchangePublicToken(props.public_token);
      setAccountInitDone(true);
    }

    initAccountsFromPlaidLink();
  }, [props.public_token]);

  async function addSelectedAccountsAndRemoveUnselected() {
    setSyncing(true);

    // Ensure the settings on the candidate accounts are synced to the cloud
    // before the cloud functions do their syncing.
    await Promise.allSettled(accountUpdatePromises.current);

    let nextPositionNum = Array.from(accounts.values()).filter(
      (acc) =>
        acc.status !== AccountStatuses.NEW_SELECTED &&
        acc.status !== AccountStatuses.NEW_UNSELECTED
    ).length;

    for (const [accountId, account] of Array.from(accounts)) {
      if (account.status === AccountStatuses.NEW_SELECTED) {
        firestoreUpdateAccount(user.uid, accountId, {
          system_data: {
            status: AccountStatuses.ACTIVE,
          },
          manual_data: {
            order_position: nextPositionNum,
          },
        });
        nextPositionNum++;
      } else if (account.status === AccountStatuses.NEW_UNSELECTED) {
        firestoreDeleteAccountById(user.uid, account, null);
      }
    }

    // sync transactions
    try {
      await syncTransactions();
    } catch (error) {
      console.log(error);
    }

    setSyncing(false);

    props.closeModal();
  }

  async function cancel() {
    setCancelling(true);

    for (const [, account] of Array.from(accounts)) {
      if (
        account.status === AccountStatuses.NEW_SELECTED ||
        account.status === AccountStatuses.NEW_UNSELECTED
      ) {
        await firestoreDeleteAccountById(user.uid, account, null);
      }
    }

    removeUnusedItemsAndInstitutions();

    props.closeModal();
  }

  if (syncing) {
    return (
      <div className={styles.candidateAccountsLoadingContainer}>
        <VerticalSpacer height={60}></VerticalSpacer>
        <l-line-wobble size="150" speed="2" color="#7d57ff"></l-line-wobble>
        <VerticalSpacer height={60}></VerticalSpacer>
        <div>Finishing up</div>
      </div>
    );
  }

  if (cancelling) {
    return (
      <div className={styles.candidateAccountsLoadingContainer}>
        <VerticalSpacer height={60}></VerticalSpacer>
        <l-line-wobble size="150" speed="2" color="#7d57ff"></l-line-wobble>
        <VerticalSpacer height={60}></VerticalSpacer>
        <div>Cleaning things up</div>
      </div>
    );
  }

  if (!accountInitDone) {
    return (
      <div className={styles.candidateAccountsLoadingContainer}>
        <VerticalSpacer height={60}></VerticalSpacer>
        <l-line-wobble size="150" speed="2" color="#7d57ff"></l-line-wobble>
        <VerticalSpacer height={60}></VerticalSpacer>
        <div>Loading your accounts</div>
      </div>
    );
  }

  let candidateAccounts: JSX.Element[] = [];
  let numSelectedAccounts = 0;
  accounts.forEach((account: BreadAccount, _) => {
    if (
      account.status === AccountStatuses.NEW_SELECTED ||
      account.status === AccountStatuses.NEW_UNSELECTED
    ) {
      candidateAccounts.push(
        <CandidateAccountRow
          account={account}
          accountUpdatePromises={accountUpdatePromises}
        ></CandidateAccountRow>
      );
    }
    if (account.status === AccountStatuses.NEW_SELECTED) {
      numSelectedAccounts++;
    }
  });

  return (
    <div className={styles.candidateAccountsContentContainer}>
      <div className={styles.candidateAccountsContainer}>
        {candidateAccounts}
      </div>
      <VerticalSpacer height={50}></VerticalSpacer>
      <div className={styles.bottomRowContainer}>
        <button onClick={cancel} className={styles.cancelButton}>
          Cancel
        </button>
        <button
          onClick={async () => {
            await addSelectedAccountsAndRemoveUnselected();
          }}
          className={buttonStyles.saveButton}
          disabled={numSelectedAccounts === 0}
        >{`Add ${numSelectedAccounts} Account${
          numSelectedAccounts === 1 ? "" : "s"
        }`}</button>
      </div>
    </div>
  );
}

function LaunchPlaidLink(props: {
  plaidLinkToken: string;
  setPlaidPublicToken: React.Dispatch<React.SetStateAction<string | null>>;
  exitPlaidLink: () => void;
}) {
  const plaidLinkIsOpenRef = useRef(false);

  async function handleLinkSuccess(
    public_token: string,
    metadata: PlaidLinkOnSuccessMetadata
  ) {
    props.setPlaidPublicToken(public_token);
  }

  const plaidConfig: PlaidLinkOptions = {
    onSuccess: handleLinkSuccess,
    onExit: (err, metadata) => {
      props.exitPlaidLink();
    },
    onEvent: (eventName, metadata) => {},
    token: props.plaidLinkToken,
  };
  const plaidLink = usePlaidLink(plaidConfig);

  useEffect(() => {
    if (plaidLink.ready && !plaidLinkIsOpenRef.current) {
      plaidLinkIsOpenRef.current = true;
      plaidLink.open();
    }
  }, [plaidLink]);

  return null;
}

function SelectingAddMethod(props: {
  setPlaidPublicToken: React.Dispatch<React.SetStateAction<string | null>>;
}): JSX.Element {
  const [plaidLinkToken, setPlaidLinkToken] = useState<string | null>(null);
  const [plaidLinkOpen, setPlaidLinkOpen] = useState<boolean>(false);
  const [unableToGetPlaidLinkToken, setUnableToGetPlaidLinkToken] =
    useState<boolean>(false);

  useEffect(() => {
    async function getLinkToken() {
      try {
        const result = (await getPlaidLinkToken()) as {
          data: { linkToken: { link_token: string } };
        };
        setPlaidLinkToken(result.data.linkToken.link_token);
      } catch (error) {
        setUnableToGetPlaidLinkToken(true);
      }
    }
    if (!plaidLinkToken) {
      getLinkToken();
    }
  }, [plaidLinkToken]);

  const menu = (
    <div>
      {/* TODO: 
  <button className={styles.launchPlaidLinkButton}>
      <Icon name={"add"} size={23}></Icon>
      <HorizontalSpacer width={10}></HorizontalSpacer>
      Add more accounts from an already-connected bank login

      Add to existing connection
    </button> */}
      <button
        onClick={async () => setPlaidLinkOpen(true)}
        className={styles.launchPlaidLinkButton}
      >
        <Icon name={"account_balance"} size={20}></Icon>
        <HorizontalSpacer width={15}></HorizontalSpacer>
        Connect a new bank login
      </button>
    </div>
  );

  if (unableToGetPlaidLinkToken) {
    return (
      <div>
        We're having trouble connecting to Plaid at the moment. Please check
        your internet connection and try again.
      </div>
    );
  }

  if (!plaidLinkOpen) {
    return menu;
  }

  if (!plaidLinkToken) {
    return (
      <div className={layoutStyles.flexH}>
        <l-line-wobble color="#84888E"></l-line-wobble>
      </div>
    );
  }

  return (
    <div>
      <div className={layoutStyles.flexH}>
        <l-line-wobble color="#84888E"></l-line-wobble>
      </div>
      <LaunchPlaidLink
        plaidLinkToken={plaidLinkToken}
        setPlaidPublicToken={props.setPlaidPublicToken}
        exitPlaidLink={() => setPlaidLinkOpen(false)}
      ></LaunchPlaidLink>
    </div>
  );
}

export function AddAccountModal(props: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
  const [plaidPublicToken, setPlaidPublicToken] = useState<string | null>(null);

  let modalSizeClass = styles.modalSmall;
  let modalContent: JSX.Element = (
    <SelectingAddMethod
      setPlaidPublicToken={setPlaidPublicToken}
    ></SelectingAddMethod>
  );
  let showCloseButton = true;
  if (plaidPublicToken !== null) {
    modalSizeClass = styles.modalLarge;
    modalContent = (
      <LinkingWithPlaid
        public_token={plaidPublicToken}
        closeModal={() => props.setIsOpen(false)}
      ></LinkingWithPlaid>
    );
    showCloseButton = false;
  }

  return (
    <Modal
      isOpen={props.isOpen}
      className={modalSizeClass}
      overlayClassName={styles.modalOverlay}
      ariaHideApp={false}
    >
      <div className={styles.contentContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.title}>Add Accounts</div>
          {showCloseButton ? (
            <Icon
              name="close"
              size={23}
              className={styles.closeButton}
              onClick={() => props.setIsOpen(false)}
            ></Icon>
          ) : null}
        </div>
        {modalContent}
      </div>
    </Modal>
  );
}
