import { buildClasses } from "../utils/buildClasses";
import { useEffect, useRef, useState } from "react";
import { keyCodes } from "../utils/KeyCodes";

export function RyeEditableText({
  startingText,
  save,
  textSize = "text-md",
  fontWeight = "font-normal",
  focus = false,
  ackFocus = () => {},
}: {
  startingText: string;
  save: (newText: string) => void;
  textSize?: "text-md" | "text-lg";
  fontWeight?: "font-normal" | "font-medium";
  focus?: boolean;
  ackFocus?: () => void;
}): JSX.Element {
  const [text, setText] = useState(startingText);
  const inputRef = useRef<HTMLInputElement>(null);
  const revertTextRef = useRef<string>(startingText);
  const blockBlurUpdateRef = useRef<boolean>(false);

  // Keep the text in sync with any changes to the input text. One far-fetched
  // reason to make sure we do this is in case someone changes the value
  // on a different device.
  useEffect(() => setText(startingText), [startingText]);

  useEffect(() => {
    if (focus) {
      inputRef.current?.scrollIntoView({
        block: "center",
        inline: "center",
        behavior: "smooth",
      });
      inputRef.current?.select(); // this takes care of focusing too!
      ackFocus();
    }
  }, [ackFocus, focus]);

  function handleOnKeyUp(e: React.KeyboardEvent<HTMLInputElement>): void {
    switch (e.code) {
      case keyCodes.ESC:
        blockBlurUpdateRef.current = true;
        inputRef.current?.blur();
        break;
      case keyCodes.ENTER:
        inputRef.current?.blur();
    }
  }

  function handleBlur() {
    if (!blockBlurUpdateRef.current) {
      save(text);
    } else {
      setText(revertTextRef?.current);
    }
  }

  function handleFocus() {
    revertTextRef.current = text;
    blockBlurUpdateRef.current = false;
  }

  return (
    <input
      ref={inputRef}
      className={buildClasses(
        textSize,
        fontWeight,
        "w-full",
        "px-2",
        "py-0.5",
        "bg-transparent",
        "border",
        "border-transparent",
        "rounded-md",
        "flex-grow",
        "flex-shrink",
        "hover:border-rock-200",
        "focus:border-purple",
        "focus:shadow-glow",
        "focus:shadow-purple-100",
        "outline-none"
      )}
      value={text}
      onChange={(e) => setText(e.target.value)}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyUp={(e) => handleOnKeyUp(e)}
    ></input>
  );
}
