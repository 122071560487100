import { DefaultTransactionColumnHeader } from "./DefaultTransactionColumnHeader";
import { TransactionColumn } from "./TransactionColumn";
import { TransactionColumnSizer } from "./TransactionColumnSizer";

export function TransactionColumnHeaderDate(): JSX.Element {
  return (
    <TransactionColumnSizer column={TransactionColumn.DATE}>
      <DefaultTransactionColumnHeader
        column={TransactionColumn.DATE}
        text={"Date"}
      />
    </TransactionColumnSizer>
  );
}
