import { DefaultTransactionColumnHeader } from "./DefaultTransactionColumnHeader";
import { TransactionColumn } from "./TransactionColumn";
import { TransactionColumnSizer } from "./TransactionColumnSizer";

export function TransactionColumnHeaderOrigin(): JSX.Element {
  // TODO: add tooltip back in, i.e.
  //          "For some transactions,
  //          like credit card transactions, the origin is the Merchant.
  //          For others, it is the type (and sometimes subtype) of the
  //          transaction, as categorized by your financial institution."
  return (
    <TransactionColumnSizer column={TransactionColumn.ORIGIN}>
      <DefaultTransactionColumnHeader
        column={TransactionColumn.ORIGIN}
        text={"Merchant"}
      />
    </TransactionColumnSizer>
  );
}
