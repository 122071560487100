import { useState } from "react";
import { RyeMainContentCard } from "../rye/RyeMainContentCard";
import { RyeMainContentSpacer } from "../rye/RyeMainContentSpacer";
import { RyeMainContentTitle } from "../rye/RyeMainContentTitle";
import { buildClasses } from "../utils/buildClasses";
import { GeneralSettings } from "./GeneralSettings";
import { BillingSettings } from "./BillingSettings";
import { ProfileSettings } from "./ProfileSettings";
import { RyeIcon } from "../rye/RyeIcon";

enum SettingsTab {
  GENERAL,
  BILLING,
  PROFILE,
}

export function SettingsScreen(): JSX.Element {
  const [currentTab, setCurrentTab] = useState<SettingsTab>(
    SettingsTab.GENERAL
  );

  function getSettingsTab(): JSX.Element {
    switch (currentTab) {
      case SettingsTab.GENERAL:
        return <GeneralSettings />;
      case SettingsTab.BILLING:
        return <BillingSettings />;
      case SettingsTab.PROFILE:
        return <ProfileSettings />;
    }
  }

  return (
    <div className={buildClasses("flex", "w-full")}>
      <RyeMainContentCard
        roundedTr={false}
        roundedBr={false}
        flexGrow={false}
        flexShrink={false}
        width={"w-auto"}
      >
        <div className={buildClasses("p-10")}>
          <TabButton
            text={tabEnumToText(SettingsTab.GENERAL)}
            iconName="tune"
            isSelected={currentTab === SettingsTab.GENERAL}
            handleClick={() => setCurrentTab(SettingsTab.GENERAL)}
          />
          <TabSpacer />
          <TabButton
            text={tabEnumToText(SettingsTab.BILLING)}
            iconName="credit_card"
            isSelected={currentTab === SettingsTab.BILLING}
            handleClick={() => setCurrentTab(SettingsTab.BILLING)}
          />
          <TabSpacer />
          <TabButton
            text={tabEnumToText(SettingsTab.PROFILE)}
            iconName="person"
            isSelected={currentTab === SettingsTab.PROFILE}
            handleClick={() => setCurrentTab(SettingsTab.PROFILE)}
          />
        </div>
      </RyeMainContentCard>
      <RyeMainContentSpacer direction={"v"} />
      <RyeMainContentCard roundedTl={false} roundedBl={false}>
        <div className={buildClasses("p-10")}>
          <RyeMainContentTitle title={tabEnumToText(currentTab)} />
          <div className="h-10" />
          {getSettingsTab()}
        </div>
      </RyeMainContentCard>
    </div>
  );
}

function tabEnumToText(tab: SettingsTab): string {
  switch (tab) {
    case SettingsTab.GENERAL:
      return "General";
    case SettingsTab.BILLING:
      return "Billing";
    case SettingsTab.PROFILE:
      return "Profile";
  }
}

function TabButton({
  text,
  iconName,
  isSelected,
  handleClick,
}: {
  text: string;
  iconName: string;
  isSelected: boolean;
  handleClick: () => void;
}): JSX.Element {
  return (
    <div
      className={buildClasses(
        {
          if: isSelected,
          then: buildClasses("text-rock-800", "bg-rock-200"),
          else: buildClasses("text-rock-500", "hover:text-rock-800"),
        },
        "flex",
        "items-center",
        "text-md",
        "cursor-pointer",
        "px-3",
        "py-1",
        "rounded-md",
        "select-none"
      )}
      onClick={handleClick}
    >
      <RyeIcon
        name={iconName}
        size="sm"
        fill={isSelected}
        className={buildClasses("mr-2", "ml-[-0.1rem]")}
      />
      {text}
    </div>
  );
}

function TabSpacer(): JSX.Element {
  return <div className="h-2" />;
}
