import { RyeIcon } from "../rye/RyeIcon";
import { buildClasses } from "../utils/buildClasses";
import { TransactionColumn } from "./TransactionColumn";
import { TransactionColumnSizer } from "./TransactionColumnSizer";

export function TransactionColumnHeaderRule(): JSX.Element {
  return (
    <TransactionColumnSizer column={TransactionColumn.RULE}>
      <div
        className={buildClasses(
          "w-full",
          "flex",
          "items-center",
          "p-0",
          "justify-center"
        )}
      >
        <RyeIcon name={"gavel"} size={"sm"} className="" />
      </div>
    </TransactionColumnSizer>
  );
}
