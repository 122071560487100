import { useNavigate } from "react-router-dom";
import breadLogo from "../images/bread_logo.png";
import { buildClasses } from "../utils/buildClasses";

export function BreadLogo() {
  const navigate = useNavigate();

  return (
    <img
      src={breadLogo}
      className={buildClasses("w-7", "cursor-pointer")}
      alt="Bread logo"
      onClick={() => navigate("/")}
    ></img>
  );
}
