import { buildClasses } from "../utils/buildClasses";
import { TransactionColumnHeaderAccount } from "./TransactionColumnHeaderAccount";
import { TransactionColumnHeaderOrigin } from "./TransactionColumnHeaderOrigin";
import { TransactionColumnHeaderDescription } from "./TransactionColumnHeaderDescription";
import { TransactionColumnHeaderCategory } from "./TransactionColumnHeaderCategory";
import { TransactionColumnHeaderRule } from "./TransactionColumnHeaderRule";
import { TransactionColumnHeaderAmount } from "./TransactionColumnHeaderAmount";
import { TransactionColumnHeaderDate } from "./TransactionColumnHeaderDate";
import { TransactionColumnHeaderReviewed } from "./TransactionColumnHeaderReviewed";
import { TransactionColumnSpacer } from "./TransactionColumnSpacer";

export function TransactionsHeader(): JSX.Element {
  return (
    <div
      className={buildClasses(
        "sticky",
        "top-0",
        "bg-surface-lighter",
        "h-10",
        "z-10",
        "flex",
        "items-center",
        "justify-center",
        "px-2",
        "py-1",
        "border-b",
        "border-b-surface-darkerr",
        "text-md",
        "font-medium"
      )}
    >
      <TransactionColumnHeaderDate />
      <TransactionColumnSpacer />
      <TransactionColumnHeaderAccount />
      <TransactionColumnSpacer />
      <TransactionColumnHeaderOrigin />
      <TransactionColumnSpacer />
      <TransactionColumnHeaderDescription />
      <TransactionColumnSpacer />
      <TransactionColumnHeaderCategory />
      <TransactionColumnSpacer />
      <TransactionColumnHeaderRule />
      <TransactionColumnSpacer />
      <TransactionColumnHeaderReviewed />
      <TransactionColumnSpacer />
      <TransactionColumnHeaderAmount />
    </div>
  );
}
