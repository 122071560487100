import { useEffect, useRef, useState } from "react";
import { Icon } from "../Icon";
import { HorizontalSpacer } from "../utils/Utils";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useClickOutsideElement } from "../utils/customHooks";
import { buildClasses } from "../utils/buildClasses";

function DropDownSelection(props: {
  selectedEntryName: string;
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  width: number;
  color: string;
}) {
  const [localExpand, setLocalExpand] = useState(!props.isExpanded); // used to animate shadow glow
  useEffect(() => setLocalExpand(props.isExpanded), [props.isExpanded]);

  return (
    <div
      className={buildClasses(
        {
          if: localExpand,
          then: buildClasses(
            "border-purple-400",
            "shadow-glow",
            "shadow-purple-500/20"
          ),
          else: buildClasses("shadow-none", "border-rock-200"),
        },
        "inline-flex",
        "items-center",
        "justify-between",
        "h-10",
        "w-60",
        "px-3",
        "bg-white",
        "text-purple-500",
        "rounded-md",
        "border",
        "cursor-pointer",
        "select-none",
        "transition-shadow",
        "transition-border",
        "duration-150"
      )}
      onClick={() => props.setIsExpanded(!props.isExpanded)}
    >
      <div
        className={buildClasses(
          "w-full",
          "block",
          "whitespace-pre",
          "overflow-hidden",
          "text-ellipsis"
        )}
      >
        {props.selectedEntryName}
      </div>
      <HorizontalSpacer width={10} />
      <Icon
        name={props.isExpanded ? "keyboard_arrow_up" : "keyboard_arrow_down"}
        size={20}
      />
    </div>
  );
}

function ExpandedDropDown(props: {
  selectedEntryName: string;
  width: number;
  color: string;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  entries: Map<string, string>;
  setSelectedEntryId: React.Dispatch<React.SetStateAction<string>>;
  onChange: (id: string) => void;
}) {
  const [localExpand, setLocalExpand] = useState(false); // used to animate the shadow
  const expandedContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => setLocalExpand(true), []);

  useClickOutsideElement(
    expandedContainerRef,
    () => props.setIsExpanded(false),
    true,
    document
  );

  function handleEntryClick(id: string) {
    props.setSelectedEntryId(id);
    props.setIsExpanded(false);
    props.onChange(id);
  }

  const menu: JSX.Element[] = [];
  props.entries.forEach((name, id) =>
    menu.push(
      <button
        className={buildClasses(
          "h-10",
          "block",
          "text-left",
          "w-full",
          "border-none",
          "bg-white",
          "px-3",
          "break-words",
          "cursor-pointer",
          "outline-none",
          "overflow-hidden",
          "text-ellipsis",
          "whitespace-pre",
          "rounded-none",
          "hover:bg-purple-100"
        )}
        onClick={() => handleEntryClick(id)}
      >
        {name}
      </button>
    )
  );

  return (
    <div
      className={buildClasses("relative", "inline-block", "h-10")}
      ref={expandedContainerRef}
    >
      <DropDownSelection
        key="dropDownSelection"
        selectedEntryName={props.selectedEntryName}
        width={props.width}
        color={props.color}
        isExpanded={true}
        setIsExpanded={props.setIsExpanded}
      />

      {/* For unknown reasons, the custom scrollbar is being super
      fickle in this specific instance. It seems to be caused by the fact that
      the custom scrollbar content was not taking on the right overflow
      properies. This is the only configuration I tried where I could get the
      custom scrollbar to show up properly, so here we are. */}
      <div
        className={buildClasses(
          {
            if: localExpand,
            then: buildClasses("shadow-md", "border-tiny", "border-gray-200"),
            else: buildClasses("shadow-none", "border-none"),
          },
          "w-full",
          "max-h-52",
          "block",
          "absolute",
          "z-10",
          "rounded-md",
          "overflow-y-auto",
          "mt-2",
          "overflow-hidden",
          "transition-shadow",
          "transition-border"
        )}
      >
        <OverlayScrollbarsComponent
          options={{
            scrollbars: { autoHide: "never" },
            overflow: { x: "hidden" },
          }}
          className={"max-h-52"}
        >
          {menu}
        </OverlayScrollbarsComponent>
      </div>
    </div>
  );
}

function CollapsedDropDown(props: {
  selectedEntryName: string;
  width: number;
  color: string;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <DropDownSelection
      key="dropDownSelection"
      selectedEntryName={props.selectedEntryName}
      width={props.width}
      color={props.color}
      isExpanded={false}
      setIsExpanded={props.setIsExpanded}
    />
  );
}

// NOTE: According to the docs, "A Map object iterates entries, keys, and values
// in the order of entry insertion."
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map#objects_vs._maps
export function RyeDropDown({
  entries,
  initEntryId,
  onChange,
  width = 250,
  color = "red",
}: {
  entries: Map<string, string>;
  initEntryId: string;
  onChange: (id: string) => void;
  width?: number;
  color?: string;
}) {
  const [selectedEntryId, setSelectedEntryId] = useState<string>(initEntryId);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  if (isExpanded) {
    return (
      <ExpandedDropDown
        selectedEntryName={entries.get(selectedEntryId) ?? ""}
        width={width}
        color={color}
        setIsExpanded={setIsExpanded}
        entries={entries}
        setSelectedEntryId={setSelectedEntryId}
        onChange={onChange}
      ></ExpandedDropDown>
    );
  }

  return (
    <CollapsedDropDown
      selectedEntryName={entries.get(selectedEntryId) ?? ""}
      width={width}
      color={color}
      setIsExpanded={setIsExpanded}
    ></CollapsedDropDown>
  );
}
