// IMPORTANT: The three definitions below must be kept in sync
// anytime this file is modified.

// IMPORTANT: Values cannot be removed without running a backfill job on the DB
// (because this will invalidate values in our type system that are already in
// use).

// You might be thinking:
// "this seems unnecessarily complicated... why didn't he use a typescript enum?
// Why is the same thing defined three times? What's going on here?"
//
// Well, this file should change very infrequently (if ever). And, with these
// three definitions this funnily-constructed enum becomes very easy to use
// and has very elegant typing across zod and typescript.

export const MainNavScreens: {
  ACCOUNTS: "ACCOUNTS";
  BUDGETS: "BUDGETS";
  TRANSACTIONS: "TRANSACTIONS";
  CATEGORIES: "CATEGORIES";
  RULES: "RULES";
  SETTINGS: "SETTINGS";
} = {
  ACCOUNTS: "ACCOUNTS",
  BUDGETS: "BUDGETS",
  TRANSACTIONS: "TRANSACTIONS",
  CATEGORIES: "CATEGORIES",
  RULES: "RULES",
  SETTINGS: "SETTINGS",
};

// For use with typescript types.
export type MainNavScreens = keyof typeof MainNavScreens;

// For use with enums in zod schemas (https://zod.dev/?id=zod-enums).
// Must keep this up-to-date with the values defined in MainNavScreen above!
export const MAIN_NAV_SCREEN_VALUES = [
  "ACCOUNTS",
  "BUDGETS",
  "TRANSACTIONS",
  "CATEGORIES",
  "RULES",
  "SETTINGS",
] as const;
