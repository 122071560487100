import { useContext } from "react";
import { buildClasses } from "../utils/buildClasses";
import { TransactionColumn } from "./TransactionColumn";
import { TransactionColumnSizer } from "./TransactionColumnSizer";
import { TransactionReviewedIcon } from "./TransactionReviewedIcon";
import { TransactionsNavContext } from "./TransactionsScreen";

export function TransactionColumnHeaderReviewed(): JSX.Element {
  const [transactionsNav, setTransactionsNav] = useContext(
    TransactionsNavContext
  );

  function rotateBooleanFilter(filterValue: boolean | null): boolean | null {
    if (filterValue === null) {
      return false;
    } else if (filterValue === false) {
      return true;
    } else {
      // if false
      return null;
    }
  }

  return (
    <TransactionColumnSizer column={TransactionColumn.REVIEWED}>
      <div
        className={buildClasses(
          "w-full",
          "flex",
          "items-center",
          "p-0",
          "justify-center"
        )}
      >
        <div
          className={buildClasses("flex", "items-center", "justify-center")}
          onClick={() => {
            setTransactionsNav((prevState) => ({
              ...prevState,
              reviewedFilter: rotateBooleanFilter(prevState.reviewedFilter),
            }));
          }}
        >
          <TransactionReviewedIcon reviewed={transactionsNav.reviewedFilter} />
        </div>
      </div>
    </TransactionColumnSizer>
  );
}
