import { Fragment, useState } from "react";
import { RyeMainContentCard } from "../rye/RyeMainContentCard";
import { buildClasses } from "../utils/buildClasses";
import { RyeButton } from "../rye/RyeButton";
import { RyeMainContentHeader } from "../rye/RyeMainContentHeader";
import { RyeMainContentSpacer } from "../rye/RyeMainContentSpacer";
import { createContextWithSetter } from "../utils/ContextUtils";
import { TransactionsTable } from "./TransactionsTable";
import { TransactionColumn } from "./TransactionColumn";
import { RyeMainContentTitle } from "../rye/RyeMainContentTitle";

export enum SortOrders {
  Ascending,
  Descending,
}

export type TransactionsNav = {
  sortColumn: TransactionColumn;
  sortOrder: SortOrders;
  reviewedFilter: boolean | null;
};

const defaultTransactionsNav = {
  sortColumn: TransactionColumn.DATE,
  sortOrder: SortOrders.Descending,
  reviewedFilter: null,
};

export const TransactionsNavContext = createContextWithSetter<TransactionsNav>(
  defaultTransactionsNav
);

export function TransactionsScreen(): JSX.Element {
  const [transactionsNav, setTransactionsNav] = useState<TransactionsNav>(
    defaultTransactionsNav
  );

  return (
    <TransactionsNavContext.Provider
      value={[transactionsNav, setTransactionsNav]}
    >
      <div
        className={buildClasses(
          "w-full",
          "h-full",
          "flex",
          "flex-col",
          "flex-grow",
          "flex-shrink",
          "min-w-1"
        )}
      >
        <RyeMainContentHeader>
          <RyeButton
            text="Search transactions"
            icon="search"
            size="sm"
            variant="outlined"
            vibe="subdued"
          />
        </RyeMainContentHeader>
        <RyeMainContentSpacer direction={"h"} />
        <RyeMainContentCard roundedTl={false} roundedTr={false}>
          <Fragment>
            <div className={buildClasses("px-8", "py-6")}>
              <RyeMainContentTitle title={"Transactions"} />
            </div>
            <TransactionsTable />
          </Fragment>
        </RyeMainContentCard>
      </div>
    </TransactionsNavContext.Provider>
  );
}
