import { RyeIcon } from "../rye/RyeIcon";
import { buildClasses } from "../utils/buildClasses";

export function TransactionReviewedIcon({
  reviewed,
}: {
  reviewed: boolean | null;
}) {
  function getIconName(): string {
    if (reviewed === true) {
      return "done";
    }
    if (reviewed === false) {
      return "circle";
    }
    return "done";
  }

  function getIconSize(): "md" | "custom" {
    if (reviewed === false) {
      return "custom";
    }
    return "md";
  }

  function getIconFill(): boolean {
    if (reviewed === false) {
      return true;
    }
    return false;
  }

  return (
    <RyeIcon
      name={getIconName()}
      size={getIconSize()}
      fill={getIconFill()}
      className={buildClasses(
        {
          switch: reviewed,
          cases: new Map([
            [true, buildClasses("text-purple")],
            [false, buildClasses("text-yellow", "text-[0.7rem]")],
          ]),
        },
        "cursor-pointer",
        "select-none"
      )}
    />
  );
}
