import { DefaultTransactionColumnHeader } from "./DefaultTransactionColumnHeader";
import { TransactionColumn } from "./TransactionColumn";
import { TransactionColumnSizer } from "./TransactionColumnSizer";

export function TransactionColumnHeaderDescription(): JSX.Element {
  return (
    <TransactionColumnSizer column={TransactionColumn.DESCRIPTION}>
      <DefaultTransactionColumnHeader
        column={TransactionColumn.DESCRIPTION}
        text={"Description"}
      />
    </TransactionColumnSizer>
  );
}
