import {
  AccountStatuses,
  BreadTransaction,
  FirestoreCollectionNames,
  FirestoreDocCategory,
  FirestoreDocBudget,
  FirestoreDocRule,
  FirestoreDocUser,
  firestoreDocBudgetSchema,
  firestoreDocCategorySchema,
  firestoreDocRuleSchema,
  firestoreDocTransactionSchema,
  matchTransactionToRule,
  parseBreadTransaction,
  firestoreDocCategoryGroupSchema,
} from "breadcommon";
import { FirestoreDocTransaction } from "breadcommon";
import { FirestoreDocAccount } from "breadcommon";
import {
  BreadAccount,
  BreadInstitution,
  firestoreDocAccountSchema,
  firestoreDocInstitutionSchema,
  parseBreadAccount,
  parseBreadInstitution,
} from "breadcommon";
import { getApp } from "firebase/app";
import {
  getFirestore,
  onSnapshot,
  collection,
  query,
  deleteDoc,
  doc,
  getDocs,
  writeBatch,
  setDoc,
  Unsubscribe,
  WriteBatch,
  updateDoc,
  deleteField,
  DocumentReference,
  DocumentData,
  FieldValue,
} from "firebase/firestore";
import { Dayjs } from "dayjs";
import { FirestoreDocCategoryGroup } from "breadcommon";
import { ItemsGroup } from "../rye/RyeDraggableGroupedList";

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export async function firestoreCreateUser(userId: string) {
  const user: FirestoreDocUser = {
    uid: userId,
  };

  const db = getFirestore(getApp());
  return setDoc(doc(db, FirestoreCollectionNames.users.name, userId), user);
}

export function firestoreInstitutionsSubscribe(
  userId: string,
  save: (map: Map<string, BreadInstitution>) => void,
  markInitialLoadComplete: () => void
): Unsubscribe {
  const db = getFirestore(getApp());
  return onSnapshot(
    collection(
      db,
      FirestoreCollectionNames.users.name,
      userId,
      FirestoreCollectionNames.users.institutions.name
    ),
    (querySnapshot) => {
      markInitialLoadComplete();
      let institutions = new Map<string, BreadInstitution>();
      querySnapshot.forEach((institutionDoc) => {
        const institutionParseResult = firestoreDocInstitutionSchema.safeParse(
          institutionDoc.data()
        );
        if (!institutionParseResult.success) {
          return;
        }
        const institution = parseBreadInstitution(institutionParseResult.data);
        if (!institution) {
          return;
        }
        institutions.set(institution.id, institution);
      });
      save(institutions);
    }
  );
}

export function firestoreAccountsSubscribe(
  userId: string,
  save: (map: Map<string, BreadAccount>) => void,
  markInitialLoadComplete: () => void
): Unsubscribe {
  const db = getFirestore(getApp());
  return onSnapshot(
    collection(
      db,
      FirestoreCollectionNames.users.name,
      userId,
      FirestoreCollectionNames.users.accounts.name
    ),
    (querySnapshot) => {
      markInitialLoadComplete();
      let accounts = new Map<string, BreadAccount>();
      querySnapshot.forEach((accountDoc) => {
        const firestoreAccountParseResult = firestoreDocAccountSchema.safeParse(
          accountDoc.data()
        );
        if (!firestoreAccountParseResult.success) {
          return;
        }
        const breadAccount = parseBreadAccount(
          firestoreAccountParseResult.data
        );
        if (breadAccount === null) {
          return;
        }
        accounts.set(breadAccount.id, breadAccount);
      });
      save(accounts);
    }
  );
}

export async function firestoreUpdateAccount(
  userId: string,
  accountId: string,
  update: RecursivePartial<FirestoreDocAccount>,
  writeBatch: WriteBatch | null = null
) {
  const db = getFirestore(getApp());

  if (writeBatch) {
    return writeBatch.set(
      doc(
        db,
        FirestoreCollectionNames.users.name,
        userId,
        FirestoreCollectionNames.users.accounts.name,
        accountId
      ),
      update,
      { merge: true }
    );
  }

  return setDoc(
    doc(
      db,
      FirestoreCollectionNames.users.name,
      userId,
      FirestoreCollectionNames.users.accounts.name,
      accountId
    ),
    update,
    {
      merge: true,
    }
  );
}

async function firestoreDeleteTransactionsByAccountId(
  userId: string,
  accountId: string,
  batch: WriteBatch
) {
  const db = getFirestore(getApp());
  const transactionsSnapshot = await getDocs(
    query(
      collection(
        db,
        FirestoreCollectionNames.users.name,
        userId,
        FirestoreCollectionNames.users.transactions.name
      )
    )
  );
  for (const t of transactionsSnapshot.docs) {
    const transaction = firestoreDocTransactionSchema.safeParse(t.data());
    if (
      transaction.success &&
      (transaction.data.plaid_data?.account_id === accountId ||
        transaction.data.plaid_investment_data?.account_id === accountId)
    ) {
      batch.delete(t.ref);
    }
  }
}

export async function firestoreDeleteAccountById(
  userId: string,
  accountToDelete: BreadAccount,
  // This is null when we are sort of canceling adding an account,
  // and we don't need to update the order positions of other accounts.
  accounts: BreadAccount[] | null,
  cascadeTransactionDeletions: boolean = false
) {
  const db = getFirestore(getApp());

  if (accounts === null) {
    return deleteDoc(
      doc(
        db,
        FirestoreCollectionNames.users.name,
        userId,
        FirestoreCollectionNames.users.accounts.name,
        accountToDelete.id
      )
    );
  }

  // A batched write is just a transaction that only makes writes
  // https://firebase.google.com/docs/firestore/manage-data/transactions#batched-writes
  const batch = writeBatch(db);

  batch.delete(
    doc(
      db,
      FirestoreCollectionNames.users.name,
      userId,
      FirestoreCollectionNames.users.accounts.name,
      accountToDelete.id
    )
  );

  for (const account of accounts) {
    if (
      account.status !== AccountStatuses.NEW_UNSELECTED &&
      account.order_position > accountToDelete.order_position
    ) {
      firestoreUpdateAccount(
        userId,
        account.id,
        {
          manual_data: {
            order_position: account.order_position - 1,
          },
        },
        batch
      );
    }
  }

  if (cascadeTransactionDeletions) {
    // need to await here because we are doing a read that is not part of a
    // firestore transaction inside this call
    await firestoreDeleteTransactionsByAccountId(
      userId,
      accountToDelete.id,
      batch
    );
  }

  return batch.commit();
}

export function firestoreUpdateAccountsOrder(
  userId: string,
  newAccountIdsInOrder: string[]
): Promise<void> {
  const db = getFirestore(getApp());
  const batch = writeBatch(db);

  let order_pos = 0;
  for (const accountId of newAccountIdsInOrder) {
    firestoreUpdateAccount(
      userId,
      accountId,
      { manual_data: { order_position: order_pos } },
      batch
    );
    order_pos++;
  }

  return batch.commit();
}

export function firestoreTransactionsSubscribe(
  userId: string,
  save: (
    txnChanges: Map<string, BreadTransaction>,
    txnDeletions: string[]
  ) => void,
  markInitialLoadComplete: () => void
): Unsubscribe {
  const db = getFirestore(getApp());
  return onSnapshot(
    query(
      collection(
        db,
        FirestoreCollectionNames.users.name,
        userId,
        FirestoreCollectionNames.users.transactions.name
      )
    ),
    (querySnapshot) => {
      markInitialLoadComplete();
      let txnChanges = new Map<string, BreadTransaction>();
      let txnDeletions: string[] = [];
      querySnapshot.docChanges().forEach((docChange) => {
        if (docChange.type === "added" || docChange.type === "modified") {
          const txnParseResult = firestoreDocTransactionSchema.safeParse(
            docChange.doc.data()
          );
          if (!txnParseResult.success) {
            return;
          }
          const txn = parseBreadTransaction(txnParseResult.data);
          if (!txn) {
            return;
          }
          txnChanges.set(txn.id, txn);
        } else if (docChange.type === "removed") {
          txnDeletions.push(docChange.doc.id);
        }
      });
      save(txnChanges, txnDeletions);
    }
  );
}

export async function firestoreUpdateTransaction(
  userId: string,
  transactionId: string,
  update: RecursivePartial<FirestoreDocTransaction>,
  writeBatch: WriteBatch | null = null
) {
  const db = getFirestore(getApp());
  if (writeBatch) {
    return writeBatch.set(
      doc(
        db,
        FirestoreCollectionNames.users.name,
        userId,
        FirestoreCollectionNames.users.transactions.name,
        transactionId
      ),
      update,
      { merge: true }
    );
  }
  return setDoc(
    doc(
      db,
      FirestoreCollectionNames.users.name,
      userId,
      FirestoreCollectionNames.users.transactions.name,
      transactionId
    ),
    update,
    { merge: true }
  );
}

export function firestoreCategoryGroupsSubscribe(
  userId: string,
  save: (map: Map<string, FirestoreDocCategoryGroup>) => void,
  markInitialLoadComplete: () => void
): Unsubscribe {
  const db = getFirestore(getApp());
  return onSnapshot(
    collection(
      db,
      FirestoreCollectionNames.users.name,
      userId,
      FirestoreCollectionNames.users.category_groups.name
    ),
    (querySnapshot) => {
      markInitialLoadComplete();
      let categoryGroups = new Map<string, FirestoreDocCategoryGroup>();
      querySnapshot.docs.forEach((categoryGroupDoc) => {
        const categoryGroupParseResult =
          firestoreDocCategoryGroupSchema.safeParse(categoryGroupDoc.data());
        if (categoryGroupParseResult.success) {
          categoryGroups.set(
            categoryGroupParseResult.data.id,
            categoryGroupParseResult.data
          );
        }
      });
      save(categoryGroups);
    }
  );
}

export async function firestoreCreateCategoryGroup(
  userId: string,
  categoryGroupWithoutId: Omit<FirestoreDocCategoryGroup, "id">
): Promise<void> {
  const db = getFirestore(getApp());
  const newCategoryGroupDocRef = doc(
    collection(
      db,
      FirestoreCollectionNames.users.name,
      userId,
      FirestoreCollectionNames.users.category_groups.name
    )
  );
  const newCategoryGroupId = newCategoryGroupDocRef.id;
  const categoryGroup: FirestoreDocCategoryGroup = {
    ...categoryGroupWithoutId,
    id: newCategoryGroupId,
  };

  return setDoc(newCategoryGroupDocRef, categoryGroup);
}

export async function firestoreUpdateCategoryGroup(
  userId: string,
  categoryGroupId: string,
  update: RecursivePartial<FirestoreDocCategoryGroup>,
  writeBatch: WriteBatch | null = null
) {
  const db = getFirestore(getApp());
  if (writeBatch) {
    return writeBatch.set(
      doc(
        db,
        FirestoreCollectionNames.users.name,
        userId,
        FirestoreCollectionNames.users.category_groups.name,
        categoryGroupId
      ),
      update,
      { merge: true }
    );
  }
  return setDoc(
    doc(
      db,
      FirestoreCollectionNames.users.name,
      userId,
      FirestoreCollectionNames.users.category_groups.name,
      categoryGroupId
    ),
    update,
    {
      merge: true,
    }
  );
}

export function firestoreUpdateCategoryGroupsOrder(
  userId: string,
  newCategoryGroupIdsOrder: string[]
): Promise<void> {
  const db = getFirestore(getApp());
  const batch = writeBatch(db);

  let i = 0;
  for (const id of newCategoryGroupIdsOrder) {
    firestoreUpdateCategoryGroup(
      userId,
      id,
      {
        order_position: i,
      },
      batch
    );
    i++;
  }
  return batch.commit();
}

export function firestoreCategoriesSubscribe(
  userId: string,
  save: (map: Map<string, FirestoreDocCategory>) => void,
  markInitialLoadComplete: () => void
): Unsubscribe {
  const db = getFirestore(getApp());
  return onSnapshot(
    collection(
      db,
      FirestoreCollectionNames.users.name,
      userId,
      FirestoreCollectionNames.users.categories.name
    ),
    (querySnapshot) => {
      markInitialLoadComplete();
      let categories = new Map<string, FirestoreDocCategory>();
      querySnapshot.docs.forEach((categoryDoc) => {
        const categoryParseResult = firestoreDocCategorySchema.safeParse(
          categoryDoc.data()
        );
        if (categoryParseResult.success) {
          categories.set(categoryParseResult.data.id, categoryParseResult.data);
        }
      });
      save(categories);
    }
  );
}

export async function firestoreCreateCategory(
  userId: string,
  categoryWithoutId: Omit<FirestoreDocCategory, "id">
) {
  const db = getFirestore(getApp());
  const newCategoryDocRef = doc(
    collection(
      db,
      FirestoreCollectionNames.users.name,
      userId,
      FirestoreCollectionNames.users.categories.name
    )
  );
  const newCategoryId = newCategoryDocRef.id;
  const category: FirestoreDocCategory = {
    ...categoryWithoutId,
    id: newCategoryId,
  };

  // add new category to all existing budgets
  const budgetsSnapshot = await getDocs(
    query(
      collection(
        db,
        FirestoreCollectionNames.users.name,
        userId,
        FirestoreCollectionNames.users.budgets.name
      )
    )
  );
  budgetsSnapshot.docs.forEach(
    async (budgetDoc) =>
      await firestoreAddEmptyBudgetLineItem(userId, budgetDoc.id, newCategoryId)
  );
  return setDoc(newCategoryDocRef, category);
}

export async function firestoreUpdateCategory(
  userId: string,
  categoryId: string,
  update: RecursivePartial<FirestoreDocCategory>,
  writeBatch: WriteBatch | null = null
) {
  const db = getFirestore(getApp());
  if (writeBatch) {
    return writeBatch.set(
      doc(
        db,
        FirestoreCollectionNames.users.name,
        userId,
        FirestoreCollectionNames.users.categories.name,
        categoryId
      ),
      update,
      { merge: true }
    );
  }
  return setDoc(
    doc(
      db,
      FirestoreCollectionNames.users.name,
      userId,
      FirestoreCollectionNames.users.categories.name,
      categoryId
    ),
    update,
    {
      merge: true,
    }
  );
}

export function firestoreUpdateCategoriesOrder(
  userId: string,
  newGroupedCategories: ItemsGroup[]
): Promise<void> {
  const db = getFirestore(getApp());
  const batch = writeBatch(db);

  for (const categoryGroup of newGroupedCategories) {
    let categoryIndex = 0;
    for (const category of categoryGroup.items) {
      firestoreUpdateCategory(
        userId,
        category.id,
        { category_group_id: categoryGroup.id, order_position: categoryIndex },
        batch
      );
      categoryIndex++;
    }
  }
  return batch.commit();
}

export async function firestoreTempUpdateCategoryOrderPosition(
  userId: string,
  categoryId: string,
  newPosition: number
) {
  firestoreUpdateCategory(userId, categoryId, {
    order_position: newPosition,
  });
}

export async function firestoreFinalUpdateCategoryOrderPosition(
  userId: string,
  sortedCategories: FirestoreDocCategory[],
  oldPosition: number,
  tempPosition: number,
  newPosition: number
) {
  // Deliberately not using a batched write here so the UI updates fast.
  // It should be fairly safe since the sortedCategories should be saved in the
  // closure (I think?).

  const db = getFirestore(getApp());
  const batch = writeBatch(db);

  for (const category of sortedCategories) {
    if (
      category.order_position > oldPosition &&
      category.order_position <= newPosition
    ) {
      firestoreUpdateCategory(
        userId,
        category.id,
        {
          order_position: category.order_position - 1,
        },
        batch
      );
    }
    if (category.order_position === tempPosition) {
      firestoreUpdateCategory(
        userId,
        category.id,
        {
          order_position: newPosition,
        },
        batch
      );
    }
    if (
      category.order_position < oldPosition &&
      category.order_position >= newPosition
    ) {
      firestoreUpdateCategory(
        userId,
        category.id,
        {
          order_position: category.order_position + 1,
        },
        batch
      );
    }
  }

  batch.commit();
}

export async function firestoreDeleteCategoryByIdAndUpdateTransactions(
  userId: string,
  categoryToDelete: FirestoreDocCategory,
  categories: FirestoreDocCategory[]
): Promise<void> {
  const db = getFirestore(getApp());
  const batch = writeBatch(db);

  batch.delete(
    doc(
      db,
      FirestoreCollectionNames.users.name,
      userId,
      FirestoreCollectionNames.users.categories.name,
      categoryToDelete.id
    )
  );

  // update other category's order positions
  for (const category of categories) {
    if (category.order_position > categoryToDelete.order_position) {
      firestoreUpdateCategory(
        userId,
        category.id,
        {
          order_position: category.order_position - 1,
        },
        batch
      );
    }
  }

  // remove category from any transactions
  const transactionsSnapshot = await getDocs(
    query(
      collection(
        db,
        FirestoreCollectionNames.users.name,
        userId,
        FirestoreCollectionNames.users.transactions.name
      )
    )
  );
  for (const t of transactionsSnapshot.docs) {
    const transaction = firestoreDocTransactionSchema.safeParse(t.data());
    if (
      transaction.success &&
      transaction.data.manual_data.category_id === categoryToDelete.id &&
      transaction.data.plaid_data?.transaction_id
    ) {
      firestoreUpdateTransaction(
        userId,
        transaction.data.plaid_data?.transaction_id,
        { manual_data: { category_id: null } },
        batch
      );
    }
  }

  // remove category from all existing budgets
  const budgetsSnapshot = await getDocs(
    query(
      collection(
        db,
        FirestoreCollectionNames.users.name,
        userId,
        FirestoreCollectionNames.users.budgets.name
      )
    )
  );
  budgetsSnapshot.docs.forEach(async (budgetDoc) =>
    firestoreRemoveBudgetLineItem(
      userId,
      budgetDoc.id,
      categoryToDelete.id,
      batch
    )
  );
  return batch.commit();
}

export function firestoreRulesSubscribe(
  userId: string,
  save: (
    ruleChanges: Map<string, FirestoreDocRule>,
    ruleDeletions: string[]
  ) => void,
  markInitialLoadComplete: () => void
): Unsubscribe {
  const db = getFirestore(getApp());
  return onSnapshot(
    query(
      collection(
        db,
        FirestoreCollectionNames.users.name,
        userId,
        FirestoreCollectionNames.users.rules.name
      )
    ),
    (querySnapshot) => {
      markInitialLoadComplete();
      let ruleChanges = new Map<string, FirestoreDocRule>();
      let ruleDeletions: string[] = [];
      querySnapshot.docChanges().forEach((docChange) => {
        if (docChange.type === "added" || docChange.type === "modified") {
          const ruleParseResult = firestoreDocRuleSchema.safeParse(
            docChange.doc.data()
          );
          if (!ruleParseResult.success) {
            return;
          }
          ruleChanges.set(ruleParseResult.data.id, ruleParseResult.data);
        } else if (docChange.type === "removed") {
          ruleDeletions.push(docChange.doc.id);
        }
      });
      save(ruleChanges, ruleDeletions);
    }
  );
}

export async function firestoreUpdateTransactionsAgainstRules(
  userId: string
): Promise<void> {
  const db = getFirestore(getApp());

  const batch = writeBatch(db);

  const rulesSnapshot = await getDocs(
    query(
      collection(
        db,
        FirestoreCollectionNames.users.name,
        userId,
        FirestoreCollectionNames.users.rules.name
      )
    )
  );
  let rules: Map<string, FirestoreDocRule> = new Map();
  for (const r of rulesSnapshot.docs) {
    const rule = firestoreDocRuleSchema.safeParse(r.data());
    if (rule.success) {
      rules.set(rule.data.id, rule.data);
    }
  }

  const transactionsSnapshot = await getDocs(
    query(
      collection(
        db,
        FirestoreCollectionNames.users.name,
        userId,
        FirestoreCollectionNames.users.transactions.name
      )
    )
  );
  for (const t of transactionsSnapshot.docs) {
    const transactionParse = firestoreDocTransactionSchema.safeParse(t.data());
    if (transactionParse.success) {
      const breadTransaction: BreadTransaction | null = parseBreadTransaction(
        transactionParse.data
      );
      if (!breadTransaction) {
        console.log("Null transaction while trying to apply Rules.");
        continue; // shouldn't happen, maybe shouldn't fail silently
      }
      if (breadTransaction.reviewed) {
        continue; // don't apply to Rules to transactions that have already been reviewed.
      }
      const { bestMatchRule } = matchTransactionToRule(breadTransaction, rules);
      firestoreUpdateTransaction(
        userId,
        breadTransaction.id,
        {
          manual_data: {
            category_id: bestMatchRule?.action.category_id ?? null,
          },
        },
        batch
      );
    }
  }
  return batch.commit();
}

export async function firestoreAddRuleAndUpdateTransactions(
  userId: string,
  newRuleWithoutId: Omit<FirestoreDocRule, "id">
): Promise<void> {
  const db = getFirestore(getApp());

  const newRuleDocRef = doc(
    collection(
      db,
      FirestoreCollectionNames.users.name,
      userId,
      FirestoreCollectionNames.users.rules.name
    )
  );

  const newRule: FirestoreDocRule = {
    id: newRuleDocRef.id,
    ...newRuleWithoutId,
  };

  await setDoc(newRuleDocRef, newRule);

  return firestoreUpdateTransactionsAgainstRules(userId);
}

export async function firestoreDeleteRuleAndUpdateTransactions(
  userId: string,
  ruleIdToRemove: string
) {
  const db = getFirestore(getApp());

  await deleteDoc(
    doc(
      db,
      FirestoreCollectionNames.users.name,
      userId,
      FirestoreCollectionNames.users.rules.name,
      ruleIdToRemove
    )
  );

  return firestoreUpdateTransactionsAgainstRules(userId);
}

export async function firestoreUpdateRuleAndUpdateTransactions(
  userId: string,
  ruleID: string,
  updates: RecursivePartial<FirestoreDocRule>
): Promise<void> {
  const db = getFirestore(getApp());

  await setDoc(
    doc(
      db,
      FirestoreCollectionNames.users.name,
      userId,
      FirestoreCollectionNames.users.rules.name,
      ruleID
    ),
    updates,
    { merge: true }
  );

  return firestoreUpdateTransactionsAgainstRules(userId);
}

export async function firestoreUpdateRulesOrderAndUpdateTransactions(
  userId: string,
  newRuleIdsInOrder: string[]
): Promise<void> {
  const db = getFirestore(getApp());

  const batch = writeBatch(db);

  let orderPos = 0;

  for (const ruleId of newRuleIdsInOrder) {
    const updates: RecursivePartial<FirestoreDocRule> = {
      order_position: orderPos,
    };

    batch.set(
      doc(
        db,
        FirestoreCollectionNames.users.name,
        userId,
        FirestoreCollectionNames.users.rules.name,
        ruleId
      ),
      updates,
      { merge: true }
    );

    orderPos++;
  }

  await batch.commit();

  return firestoreUpdateTransactionsAgainstRules(userId);
}

export function firestoreBudgetsSubscribe(
  userId: string,
  save: (
    budgetChanges: Map<string, FirestoreDocBudget>,
    budgetDeletions: string[]
  ) => void,
  markInitialLoadComplete: () => void
): Unsubscribe {
  const db = getFirestore(getApp());
  return onSnapshot(
    query(
      collection(
        db,
        FirestoreCollectionNames.users.name,
        userId,
        FirestoreCollectionNames.users.budgets.name
      )
    ),
    (querySnapshot) => {
      markInitialLoadComplete();
      let budgetChanges = new Map<string, FirestoreDocBudget>();
      let budgetDeletions: string[] = [];
      querySnapshot.docChanges().forEach((docChange) => {
        if (docChange.type === "added" || docChange.type === "modified") {
          const budgetParseResult = firestoreDocBudgetSchema.safeParse(
            docChange.doc.data()
          );
          if (!budgetParseResult.success) {
            return;
          }
          budgetChanges.set(docChange.doc.id, budgetParseResult.data);
        } else if (docChange.type === "removed") {
          budgetDeletions.push(docChange.doc.id);
        }
      });
      save(budgetChanges, budgetDeletions);
    }
  );
}

export async function firestoreCreateNewEmptyBudget(
  userId: string,
  name: string, // name of the budget
  beginDateTimestampSecs: Dayjs,
  endDateTimestampSecs: Dayjs,
  category_ids: string[]
): Promise<void> {
  const db = getFirestore(getApp());

  const newBudgetDoc: FirestoreDocBudget = {
    name: name,
    begin_date_timestamp_secs: beginDateTimestampSecs.unix(),
    end_date_timestamp_secs: endDateTimestampSecs.unix(),
    line_items: Object.fromEntries(
      category_ids.map((category_id) => {
        return [
          category_id,
          {
            formula: "",
          },
        ];
      })
    ),
  };

  const newBudgetDocRef = doc(
    collection(
      db,
      FirestoreCollectionNames.users.name,
      userId,
      FirestoreCollectionNames.users.budgets.name
    )
  );

  await setDoc(newBudgetDocRef, newBudgetDoc);
}

export async function firestoreUpdateBudget(
  userId: string,
  budgetId: string,
  update: RecursivePartial<Omit<FirestoreDocBudget, "line_items">>
) {
  const db = getFirestore(getApp());
  setDoc(
    doc(
      db,
      FirestoreCollectionNames.users.name,
      userId,
      FirestoreCollectionNames.users.budgets.name,
      budgetId
    ),
    update,
    { merge: true }
  );
}

export async function firestoreAddEmptyBudgetLineItem(
  userId: string,
  budgetId: string,
  categoryId: string
) {
  await firestoreUpdateBudgetLineItem(userId, budgetId, categoryId, "");
}

export async function firestoreUpdateBudgetLineItem(
  userId: string,
  budgetId: string,
  categoryId: string,
  formula: string
) {
  const db = getFirestore(getApp());
  const update: Pick<FirestoreDocBudget, "line_items"> = {
    line_items: { [categoryId]: { formula: formula } },
  };
  setDoc(
    doc(
      db,
      FirestoreCollectionNames.users.name,
      userId,
      FirestoreCollectionNames.users.budgets.name,
      budgetId
    ),
    update,
    { merge: true }
  );
}

export async function firestoreRemoveBudgetLineItem(
  userId: string,
  budgetId: string,
  categoryId: string,
  writeBatch: WriteBatch | null = null
) {
  const db = getFirestore(getApp());
  const updateArgs: [
    DocumentReference<DocumentData, DocumentData>,
    { [x: string]: FieldValue }
  ] = [
    doc(
      db,
      FirestoreCollectionNames.users.name,
      userId,
      FirestoreCollectionNames.users.budgets.name,
      budgetId
    ),
    {
      ["line_items." + categoryId]: deleteField(),
    },
  ];
  if (writeBatch !== null) {
    writeBatch.update(...updateArgs);
  } else {
    updateDoc(...updateArgs);
  }
}

export async function firestoreDeleteBudget(userId: string, budgetId: string) {
  const db = getFirestore(getApp());
  deleteDoc(
    doc(
      db,
      FirestoreCollectionNames.users.name,
      userId,
      FirestoreCollectionNames.users.budgets.name,
      budgetId
    )
  );
}
