import { AccountRow } from "./AccountRow";
import { AccountStatuses, BreadAccount } from "breadcommon";
import { useContext } from "react";
import { AccountsContext } from "../firebaseio/AccountsContext";
import { LoadingInitialDataContext } from "../LoadUser";
import { Skeleton } from "@mui/material";
import { RyeDraggableList } from "../rye/RyeDraggableList";
import { buildClasses } from "../utils/buildClasses";
import { RyeIcon } from "../rye/RyeIcon";
import { firestoreUpdateAccountsOrder } from "../firebaseio/firestoreIo";
import { UserContext } from "../firebaseio/UserContext";

export function AccountsList(): JSX.Element {
  const accounts = useContext(AccountsContext);
  const loadingInitialData = useContext(LoadingInitialDataContext);
  const user = useContext(UserContext);

  let accountRows: { id: string; value: JSX.Element }[] = [];
  Array.from(accounts.values())
    .sort((a, b) => a.order_position - b.order_position)
    .forEach((account: BreadAccount, account_id) => {
      if (account.status === AccountStatuses.ACTIVE) {
        accountRows.push({
          id: account.id,
          value: <AccountRow account={account}></AccountRow>,
        });
      }
    });

  if (
    loadingInitialData.accounts &&
    loadingInitialData.institutions &&
    loadingInitialData.transactions
  ) {
    return <Skeleton animation="wave" />;
  }

  if (accountRows.length === 0) {
    return (
      <div
        className={buildClasses(
          "w-full",
          "flex",
          "flex-col",
          "items-center",
          "p-10"
        )}
      >
        <RyeIcon
          name={"beach_access"}
          size={"custom"}
          className={buildClasses("text-rock-500", "text-[20rem]")}
        />
        <div className={buildClasses("text-gray-500", "mt-12")}>
          Nothing to show here yet - link an account to get started.
        </div>
      </div>
    );
  }

  return (
    <RyeDraggableList
      inputItems={accountRows}
      onOrderUpdate={function (newIdList: string[]): void {
        firestoreUpdateAccountsOrder(user.uid, newIdList);
      }}
      backgroundColor="transparent"
      itemColor={"#FBFBFB"}
      showDividingLines={true}
    />
  );
}
