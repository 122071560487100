import { buildClasses } from "../utils/buildClasses";

export function RyeMainContentSpacer({
  direction,
}: {
  direction: "h" | "v";
}): JSX.Element {
  return (
    <div
      className={buildClasses(
        {
          if: direction === "h",
          then: buildClasses("w-full", "h-px"),
          else: buildClasses("h-full", "w-px"),
        },
        "flex-grow-0",
        "flex-shrink-0"
      )}
    ></div>
  );
}
