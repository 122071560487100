import { useContext, useEffect, useState } from "react";
import { BudgetsContext } from "../firebaseio/BudgetsContext";
import styles from "./BudgetScreen.module.scss";
import { BudgetEditor } from "./BudgetEditor";
import { LoadingInitialDataContext } from "../LoadUser";
import { Skeleton } from "@mui/material";
import { HorizontalSpacer, VerticalSpacer } from "../utils/Utils";
import { DropDown } from "../common/DropDown";
import { CreateNewBudgetModal } from "./CreateNewBudgetModal";
import { Icon } from "../Icon";
import { PRIMARY_COLOR } from "../common/colors";
import { RyeDropDown } from "../rye/RyeDropDown";

function CreateNewBudgetButton() {
  const [createNewBudgetModalIsOpen, setCreateNewBudgetModalIsOpen] =
    useState<boolean>(false);

  return (
    <div>
      <button
        onClick={() => setCreateNewBudgetModalIsOpen(true)}
        className={styles.addBudgetButton}
      >
        <Icon name={"add"} size={23}></Icon>
        <HorizontalSpacer width={5}></HorizontalSpacer>
        Create Budget
      </button>
      {createNewBudgetModalIsOpen ? (
        <CreateNewBudgetModal
          isOpen={createNewBudgetModalIsOpen}
          setIsOpen={setCreateNewBudgetModalIsOpen}
        ></CreateNewBudgetModal>
      ) : null}
    </div>
  );
}

export function BudgetScreen(): JSX.Element {
  const budgets = useContext(BudgetsContext);
  const loadingInitialData = useContext(LoadingInitialDataContext);
  const [budgetId, setBudgetId] = useState<string | null>(null);

  useEffect(() => {
    if ((!budgetId || !budgets.has(budgetId)) && budgets.size > 0) {
      setBudgetId(
        Array.from(budgets.values())
          .sort(
            (a, b) =>
              b.endDateTimestampSecs.unix() - a.endDateTimestampSecs.unix()
          )
          .at(0)?.id ?? null
      );
    }
  }, [budgetId, budgets, budgets.size]);

  if (
    loadingInitialData.transactions ||
    loadingInitialData.categories ||
    loadingInitialData.budgets
  ) {
    return (
      <div>
        <Skeleton animation="wave" />
      </div>
    );
  }

  if (budgets.size === 0 || !budgetId) {
    return (
      <div className={styles.mainContainer}>
        <CreateNewBudgetButton />
        <VerticalSpacer height={20} />
        <div className={styles.card}>
          Use the Create New Budget button at the top of the page to create your
          first budget.
          <br />
          <br />
          WARNING: Budgets are very much still in Beta, so we might need to
          delete all your budgets in the near future to fix something. But feel
          free to play around with them for now.
        </div>
      </div>
    );
  } else if (budgetId === null || !budgets.has(budgetId)) {
    setBudgetId(Array.from(budgets.keys())[0]);
  }

  let budgetDropDownEntries: Map<string, string> = new Map();
  Array.from(budgets.values())
    .sort(
      (a, b) => b.endDateTimestampSecs.unix() - a.endDateTimestampSecs.unix()
    )
    .forEach((budget) => {
      budgetDropDownEntries.set(budget.id, budget.name);
    });

  return (
    <div className={styles.mainContainer}>
      <div className={styles.topRow}>
        <RyeDropDown
          key={budgetId} // allows the initEntryId to take effect when budgetId is changed from a source other than the drop down (i.e. a budget is deleted)
          entries={budgetDropDownEntries}
          initEntryId={budgetId}
          onChange={(id: string) => setBudgetId(id)}
          width={300}
          color={PRIMARY_COLOR}
        ></RyeDropDown>
        <HorizontalSpacer width={20} />
        <CreateNewBudgetButton />
      </div>
      <VerticalSpacer height={20} />
      <div className={styles.card}>
        <BudgetEditor budgetId={budgetId!} />
      </div>
    </div>
  );
}
