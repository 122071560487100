import { getAuth, signOut } from "firebase/auth";
import { RyeButton } from "../rye/RyeButton";

export function ProfileSettings(): JSX.Element {
  async function logOut() {
    const auth = getAuth();
    await signOut(auth);
  }

  return (
    <RyeButton icon="logout" text={"Sign out"} onClick={logOut}></RyeButton>
  );
}
