export enum TransactionColumn {
  DATE,
  ACCOUNT,
  ORIGIN,
  DESCRIPTION,
  CATEGORY,
  RULE,
  REVIEWED,
  AMOUNT,
}
