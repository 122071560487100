import { Merchant } from "breadcommon";
import { DefaultTransactionColumnValue } from "./DefaultTransactionColumnValue";
import { MerchantLogo } from "../common/MerchantLogo";
import { TransactionColumnSizer } from "./TransactionColumnSizer";
import { TransactionColumn } from "./TransactionColumn";
import { buildClasses } from "../utils/buildClasses";
import { Fragment, useContext } from "react";
import { TransactionRowContext } from "./TransactionRow";

export function TransactionColumnValueOrigin({
  merchant,
  type,
  subtype,
}: {
  merchant: Merchant | null;
  type: string | null;
  subtype: string | null;
}): JSX.Element {
  const { ruleForFieldHighlighting } = useContext(TransactionRowContext);

  if (merchant !== null) {
    return (
      <TransactionColumnSizer column={TransactionColumn.ORIGIN}>
        <DefaultTransactionColumnValue
          ruleHighlight={
            ruleForFieldHighlighting.get !== null &&
            ruleForFieldHighlighting.get.criteria.merchant_name !== null
          }
        >
          <Fragment>
            <MerchantLogo merchant={merchant} size={18.5}></MerchantLogo>
            <div
              className={buildClasses(
                "w-100",
                "pl-3",
                "whitespace-nowrap",
                "overflow-hidden",
                "overflow-ellipsis"
              )}
            >
              {merchant.name}
            </div>
          </Fragment>
        </DefaultTransactionColumnValue>
      </TransactionColumnSizer>
    );
  }

  if (type !== null) {
    const typeTokenCommonClasses = buildClasses(
      "py-0.5",
      "text-sm",
      "border",
      "border-gray-300"
    );

    return (
      <TransactionColumnSizer column={TransactionColumn.ORIGIN}>
        <DefaultTransactionColumnValue
          ruleHighlight={
            ruleForFieldHighlighting.get !== null &&
            (ruleForFieldHighlighting.get.criteria.type !== null ||
              ruleForFieldHighlighting.get.criteria.subtype !== null)
          }
        >
          <Fragment>
            <div
              className={buildClasses(
                typeTokenCommonClasses,
                "pr-2",
                "pl-3",
                "rounded-l-full"
              )}
            >
              {type}
            </div>
            {subtype && (
              <div
                className={buildClasses(
                  typeTokenCommonClasses,
                  "pr-3",
                  "pl-2",
                  "border-l-0",
                  "rounded-r-full"
                )}
              >
                {subtype}
              </div>
            )}
          </Fragment>
        </DefaultTransactionColumnValue>
      </TransactionColumnSizer>
    );
  }

  return <TransactionColumnSizer column={TransactionColumn.ORIGIN} />;
}
