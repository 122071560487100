import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { buildClasses } from "../utils/buildClasses";

export function RyeMainContentCard({
  children,
  fitContentHeight = false,
  flexGrow = true,
  flexShrink = true,
  width = "w-full",
  height = "",
  roundedTl = true,
  roundedTr = true,
  roundedBr = true,
  roundedBl = true,
}: {
  children: JSX.Element;
  fitContentHeight?: boolean;
  flexGrow?: boolean;
  flexShrink?: boolean;
  width?: "w-full" | "w-auto";
  height?: string;
  roundedTl?: boolean;
  roundedTr?: boolean;
  roundedBr?: boolean;
  roundedBl?: boolean;
}): JSX.Element {
  return (
    <div
      className={buildClasses(
        { if: roundedTl, then: "rounded-tl-xl" },
        { if: roundedTr, then: "rounded-tr-xl" },
        { if: roundedBr, then: "rounded-br-xl" },
        { if: roundedBl, then: "rounded-bl-xl" },
        { if: fitContentHeight, then: "h-auto", else: "h-full" },
        { if: flexGrow, then: "flex-grow", else: "flex-grow-0" },
        { if: flexShrink, then: "flex-shrink", else: "flex-shrink-0" },
        width,
        height,
        "shadow-sm",
        "overflow-y-auto",
        "bg-surface-lighter"
      )}
    >
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: "move" },
        }}
        className={buildClasses("h-full")}
      >
        {children}
      </OverlayScrollbarsComponent>
    </div>
  );
}

export const STANDARD_MAIN_CONTENT_CARD_PADDING_X = "px-8";
export const STANDARD_MAIN_CONTENT_CARD_PADDING_Y = "py-3";
