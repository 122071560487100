import * as z from "zod";

export const firestoreDocCategoryGroupSchema = z.object({
  id: z.string(),
  name: z.string(),
  created_timestamp_secs: z.number(),
  order_position: z.number(),
});

export type FirestoreDocCategoryGroup = z.infer<
  typeof firestoreDocCategoryGroupSchema
>;
