import { useEffect, useRef, useState } from "react";
import styles from "./RyeDraggableGroupedList.module.scss";
import { Icon } from "../Icon";
import { HorizontalSpacer } from "../utils/Utils";
import { buildClasses } from "../utils/buildClasses";

function RyeDraggableListItem(props: {
  item: { id: string; value: JSX.Element };
  indices: ItemIndices;
  transitionSpacerHeight: boolean;
  draggingElementHeight: React.MutableRefObject<number>;
  draggingIndices: ItemIndices | null;
  setDraggingIndices: React.Dispatch<React.SetStateAction<ItemIndices | null>>;
  setTargetIndices: (newIndex: ItemIndices | null) => void;
  itemColor: string;
  setTransitionSpacerHeight: (newVal: boolean) => void;
}): JSX.Element {
  const [draggable, setDraggable] = useState(false);
  const [dragging, setDragging] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={divRef}
      draggable={draggable}
      className={`${styles.draggableListItem} ${
        dragging ? styles.dragging : ""
      }`}
      style={{
        backgroundColor: props.itemColor,
        zIndex: 1000 - props.indices.itemIndex,
      }}
      onDrag={(e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (!dragging) {
          setDragging(true);
          props.setDraggingIndices(props.indices);
          props.setTransitionSpacerHeight(false);
          props.setTargetIndices(props.indices);
          props.draggingElementHeight.current =
            divRef.current?.getBoundingClientRect().height ?? 0;
        }
      }}
      onDragEnd={(e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setDragging(false);
        setDraggable(false);
        props.setDraggingIndices(null);
        props.setTargetIndices(null);
        props.setTransitionSpacerHeight(false);
      }}
      onDragEnter={(e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";
      }}
      onDragOver={(e: React.DragEvent<HTMLDivElement>) => {
        // See https://stackoverflow.com/questions/50230048/react-ondrop-is-not-firing
        // for why we prevent default
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";
        if (props.draggingIndices === null) {
          return;
        }
        e.stopPropagation();
        if (props.draggingIndices === null || divRef.current === null) {
          return;
        }

        const mousePositionY = e.clientY;
        const itemCenterPositionY =
          divRef.current.getBoundingClientRect().top +
          divRef.current.getBoundingClientRect().height / 2;

        if (props.draggingIndices.groupIndex !== props.indices.groupIndex) {
          if (mousePositionY < itemCenterPositionY) {
            props.setTargetIndices({
              groupIndex: props.indices.groupIndex,
              itemIndex: props.indices.itemIndex,
            });
          }
          if (mousePositionY > itemCenterPositionY) {
            props.setTargetIndices({
              groupIndex: props.indices.groupIndex,
              itemIndex: props.indices.itemIndex + 1,
            });
          }
          return;
        }

        if (
          mousePositionY < itemCenterPositionY &&
          props.draggingIndices.itemIndex < props.indices.itemIndex
        ) {
          props.setTargetIndices({
            groupIndex: props.indices.groupIndex,
            itemIndex: props.indices.itemIndex - 1,
          });
        }
        if (
          mousePositionY < itemCenterPositionY &&
          props.draggingIndices.itemIndex > props.indices.itemIndex
        ) {
          props.setTargetIndices({
            groupIndex: props.indices.groupIndex,
            itemIndex: props.indices.itemIndex,
          });
        }
        if (
          mousePositionY > itemCenterPositionY &&
          props.draggingIndices.itemIndex < props.indices.itemIndex
        ) {
          props.setTargetIndices({
            groupIndex: props.indices.groupIndex,
            itemIndex: props.indices.itemIndex,
          });
        }
        if (
          mousePositionY > itemCenterPositionY &&
          props.draggingIndices.itemIndex > props.indices.itemIndex
        ) {
          props.setTargetIndices({
            groupIndex: props.indices.groupIndex,
            itemIndex: props.indices.itemIndex + 1,
          });
        }
      }}
    >
      <div
        className={styles.dragIndicatorContainer}
        onMouseEnter={() => setDraggable(true)}
        onMouseLeave={() => setDraggable(false)}
      >
        <Icon
          name={"drag_indicator"}
          size={20}
          className={styles.dragIndicator}
        />
      </div>
      <HorizontalSpacer width={20} />
      {props.item.value}
    </div>
  );
}

function ItemSpacer(props: {
  indices: ItemIndices;
  itemDraggingIndices: ItemIndices | null;
  targetIndices: ItemIndices | null;
  collapsedHeight: number;
  transitionSpacerHeight: boolean;
  draggingElementHeight: React.MutableRefObject<number>;
  setTargetIndices: (newIndex: ItemIndices | null) => void;
  showDividingLines: boolean;
  isFirstOrLastSpacer: boolean;
  setTransitionSpacerHeight: (newVal: boolean) => void;
}): JSX.Element {
  function isExpanded() {
    return itemIndicesAreEqual(props.targetIndices, props.indices);
  }

  return (
    <div
      style={{
        height: isExpanded()
          ? props.draggingElementHeight.current - 10
          : props.collapsedHeight,
        marginTop: isExpanded() ? props.collapsedHeight + 5 : 0,
        marginBottom: isExpanded() ? props.collapsedHeight + 5 : 0,
        marginLeft: isExpanded() ? 5 : 0,
        marginRight: isExpanded() ? 5 : 0,
      }}
      className={`${styles.spacer} ${isExpanded() ? styles.expand : ""} ${
        props.transitionSpacerHeight ? styles.transitionHeight : ""
      }`}
      onDragEnter={(e) => {
        if (props.itemDraggingIndices === null) {
          return;
        }
        e.preventDefault();
        e.stopPropagation();
        if (props.itemDraggingIndices !== null) {
          props.setTargetIndices(props.indices);
        }
        e.dataTransfer.dropEffect = "move";
      }}
      onDragOver={(e) => {
        if (props.itemDraggingIndices === null) {
          return;
        }
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.dropEffect = "move";
        props.setTransitionSpacerHeight(true);
      }}
      onDragLeave={(e) => {
        if (props.itemDraggingIndices === null) {
          return;
        }
        e.stopPropagation();
        props.setTransitionSpacerHeight(true);
      }}
    >
      {props.showDividingLines &&
      !props.isFirstOrLastSpacer &&
      !isExpanded() ? (
        <div className={styles.spacerDividingLine}></div>
      ) : null}
    </div>
  );
}

function Group(props: {
  group: {
    id: string;
    value: JSX.Element;
    items: { id: string; value: JSX.Element }[];
  };
  index: number;
  groupDraggingIndex: number | null;
  itemDraggingIndices: ItemIndices | null;
  setGroupDraggingIndex: React.Dispatch<React.SetStateAction<number | null>>;
  setItemDraggingIndices: React.Dispatch<
    React.SetStateAction<ItemIndices | null>
  >;
  groupColor: string;
  itemColor: string;
  groupTargetIndex: number | null;
  itemTargetIndices: ItemIndices | null;
  collapsedItemSpacerHeight: number;
  transitionSpacerHeight: boolean;
  draggingElementHeight: React.MutableRefObject<number>;
  setGroupTargetIndex: (newIndex: number | null) => void;
  setItemTargetIndices: (newIndices: ItemIndices | null) => void;
  showItemDividingLines: boolean;
  setTransitionSpacerHeight: (newVal: boolean) => void;
}): JSX.Element {
  const [draggable, setDraggable] = useState(false);
  const [dragging, setDragging] = useState(false);

  const divRef = useRef<HTMLDivElement>(null);

  let draggableList: JSX.Element[] = [];
  draggableList.push(
    <ItemSpacer
      key={"spacer 0"}
      indices={{ groupIndex: props.index, itemIndex: 0 }}
      itemDraggingIndices={props.itemDraggingIndices}
      targetIndices={props.itemTargetIndices}
      collapsedHeight={props.collapsedItemSpacerHeight}
      transitionSpacerHeight={props.transitionSpacerHeight}
      draggingElementHeight={props.draggingElementHeight}
      setTargetIndices={props.setItemTargetIndices}
      showDividingLines={props.showItemDividingLines}
      isFirstOrLastSpacer={true}
      setTransitionSpacerHeight={props.setTransitionSpacerHeight}
    />
  );
  props.group.items.forEach((value, index) => {
    draggableList.push(
      <RyeDraggableListItem
        key={value.id}
        indices={{ groupIndex: props.index, itemIndex: index }}
        item={value}
        transitionSpacerHeight={props.transitionSpacerHeight}
        draggingElementHeight={props.draggingElementHeight}
        draggingIndices={props.itemDraggingIndices}
        setDraggingIndices={props.setItemDraggingIndices}
        setTargetIndices={props.setItemTargetIndices}
        itemColor={props.itemColor}
        setTransitionSpacerHeight={props.setTransitionSpacerHeight}
      />
    );
    if (
      !itemIndicesAreEqual(props.itemDraggingIndices, {
        groupIndex: props.index,
        itemIndex: index,
      })
    ) {
      // When dragging to get the spacing right we remove the spacer
      // whose index is 1 greater than the dragging index.
      draggableList.push(
        <ItemSpacer
          key={`spacer ${index + 1}`}
          indices={
            props.itemDraggingIndices !== null &&
            props.itemDraggingIndices.groupIndex === props.index &&
            index > props.itemDraggingIndices.itemIndex
              ? { groupIndex: props.index, itemIndex: index }
              : { groupIndex: props.index, itemIndex: index + 1 }
          }
          itemDraggingIndices={props.itemDraggingIndices}
          targetIndices={props.itemTargetIndices}
          collapsedHeight={props.collapsedItemSpacerHeight}
          transitionSpacerHeight={props.transitionSpacerHeight}
          draggingElementHeight={props.draggingElementHeight}
          setTargetIndices={props.setItemTargetIndices}
          showDividingLines={props.showItemDividingLines}
          isFirstOrLastSpacer={
            props.itemDraggingIndices?.itemIndex ===
            props.group.items.length - 1
              ? index + 2 === props.group.items.length
              : index + 1 === props.group.items.length
          }
          setTransitionSpacerHeight={props.setTransitionSpacerHeight}
        />
      );
    }
  });
  return (
    <div
      ref={divRef}
      draggable={draggable}
      className={`${styles.draggableGroup} ${dragging ? styles.dragging : ""}`}
      style={{
        backgroundColor: props.groupColor,
        zIndex: 1000 - props.index,
      }}
      onDrag={() => {
        if (!dragging) {
          setDragging(true);
          props.setGroupDraggingIndex(props.index);
          props.setTransitionSpacerHeight(false);
          props.setGroupTargetIndex(props.index);
          props.draggingElementHeight.current =
            divRef.current?.getBoundingClientRect().height ?? 0;
        }
      }}
      onDragEnd={() => {
        setDragging(false);
        setDraggable(false);
        props.setGroupDraggingIndex(null);
        props.setGroupTargetIndex(null);
        props.setTransitionSpacerHeight(false);
      }}
      onDragEnter={(e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";
      }}
      onDragOver={(e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";
        if (props.groupDraggingIndex === null || divRef.current === null) {
          return;
        }

        const mousePositionY = e.clientY;
        const itemCenterPositionY =
          divRef.current.getBoundingClientRect().top +
          divRef.current.getBoundingClientRect().height / 2;

        if (
          mousePositionY < itemCenterPositionY &&
          props.groupDraggingIndex < props.index
        ) {
          props.setGroupTargetIndex(props.index - 1);
        }
        if (
          mousePositionY < itemCenterPositionY &&
          props.groupDraggingIndex > props.index
        ) {
          props.setGroupTargetIndex(props.index);
        }
        if (
          mousePositionY > itemCenterPositionY &&
          props.groupDraggingIndex < props.index
        ) {
          props.setGroupTargetIndex(props.index);
        }
        if (
          mousePositionY > itemCenterPositionY &&
          props.groupDraggingIndex > props.index
        ) {
          props.setGroupTargetIndex(props.index + 1);
        }
      }}
    >
      <div className={styles.groupHeaderContainer}>
        <div
          className={styles.dragIndicatorContainer}
          onMouseEnter={() => setDraggable(true)}
          onMouseLeave={() => setDraggable(false)}
        >
          <Icon
            name={"drag_indicator"}
            size={20}
            className={styles.dragIndicator}
          />
        </div>
        <HorizontalSpacer width={20} />
        {props.group.value}
      </div>
      <div className={styles.itemList}>{draggableList}</div>
    </div>
  );
}

function GroupSpacer(props: {
  index: number;
  groupDraggingIndex: number | null;
  groupTargetIndex: number | null;
  collapsedHeight: number;
  transitionSpacerHeight: boolean;
  draggingElementHeight: React.MutableRefObject<number>;
  setGroupTargetIndex: (newIndex: number | null) => void;
  setTransitionSpacerHeight: (newVal: boolean) => void;
}): JSX.Element {
  function isExpanded() {
    return props.index === props.groupTargetIndex;
  }

  let expandedHeight =
    props.draggingElementHeight.current + 2 * props.collapsedHeight;
  if (props.transitionSpacerHeight) {
    expandedHeight = Math.min(
      props.draggingElementHeight.current + 2 * props.collapsedHeight,
      100
    );
  }

  return (
    <div
      style={{
        height: isExpanded() ? expandedHeight : props.collapsedHeight,
        paddingTop: isExpanded() ? props.collapsedHeight + 5 : 0,
        paddingBottom: isExpanded() ? props.collapsedHeight + 5 : 0,
        paddingLeft: isExpanded() ? 5 : 0,
        paddingRight: isExpanded() ? 5 : 0,
      }}
      className={`${styles.groupSpacer} ${
        props.transitionSpacerHeight ? styles.transitionHeight : ""
      }`}
      onDragEnter={(e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";
        if (props.groupDraggingIndex !== null) {
          props.setGroupTargetIndex(props.index);
        }
      }}
      onDragOver={(e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";
        props.setTransitionSpacerHeight(true);
      }}
      onDragLeave={() => {
        // Probably overkill to set transition spacer height here as well, but
        // just to be safe.
        props.setTransitionSpacerHeight(true);
      }}
    >
      {/* Shadowy rectangle showing drop zone */}
      <div
        className={
          buildClasses("w-full", "h-full", "rounded-lg") +
          ` ${isExpanded() ? styles.expand : ""}`
        }
      />
    </div>
  );
}

function itemIndicesAreEqual(
  indicesA: ItemIndices | null,
  indicesB: ItemIndices | null
): boolean {
  if (indicesA === null && indicesB === null) {
    return true;
  }
  if (indicesA === null || indicesB === null) {
    return false;
  }
  return (
    indicesA.groupIndex === indicesB.groupIndex &&
    indicesA.itemIndex === indicesB.itemIndex
  );
}

export interface ItemIndices {
  groupIndex: number;
  itemIndex: number;
}

export interface ItemsGroup {
  id: string;
  value: JSX.Element;
  items: { id: string; value: JSX.Element }[];
}

// NOTE: We do the re-ordering in the onDrop event instead of
// the item being dragged's onDragEnd event because onDragEnd doesn't fire
// until the dragged item is actually back where it belongs, not right when
// the dragged item is released by the mouse. We want to re-order things
// right when the mouse is released so the animation of the dragged item
// jumps into it's *new* position when released, not its old one.
// A side effect of this is we can't detect onDrop when the mouse is
// hovering over anything outside of the list (i.e. if you drag too far
// above/below/left/right of the list). Therefore, we add boundaries on
// every side of the list to detect this and indicate this to the user by
// collapsing the spacer that was open.
export function RyeDraggableGroupedList({
  inputGroupedItems,
  onGroupOrderUpdate,
  onItemOrderUpdate,
  collapsedGroupSpacerHeight = 30,
  collapsedItemSpacerHeight = 10,
  showItemDividingLines = true,
  backgroundColor = "#FFFFFF",
  groupColor = "#F9F9F7",
  itemColor = "#F9F9F7",
}: {
  inputGroupedItems: ItemsGroup[];
  onGroupOrderUpdate: (
    newIdList: string[],
    id: string,
    oldIndex: number,
    newIndex: number
  ) => void;
  onItemOrderUpdate: (
    newGroupedItems: ItemsGroup[],
    id: string,
    oldIndices: ItemIndices,
    newIndices: ItemIndices
  ) => void;
  collapsedGroupSpacerHeight?: number;
  collapsedItemSpacerHeight?: number;
  showItemDividingLines?: boolean;
  backgroundColor?: string;
  groupColor?: string;
  itemColor?: string;
}): JSX.Element {
  const [localGroupedItems, setLocalGroupedItems] =
    useState<ItemsGroup[]>(inputGroupedItems);
  // Null if no group is currently being dragged.
  const [groupDraggingIndex, setGroupDraggingIndex] = useState<number | null>(
    null
  );
  // Null if no group is currently being dragged or the group being dragged
  // is outside the valid drop area (i.e. outside the whole list).
  const [groupTargetIndex, setGroupTargetIndex] = useState<number | null>(null);
  // Null if no individual item is currently being dragged.
  const [itemDraggingIndices, setItemDraggingIndices] =
    useState<ItemIndices | null>(null);
  // Null if no indivdual item is currently being dragged or the item being
  // dragged is outside the valid drop area (i.e. outside the whole list).
  const [itemTargetIndices, setItemTargetIndices] =
    useState<ItemIndices | null>(null);
  // When true, transition (i.e. animate) changes in the height of spacers.
  const [transitionSpacerHeight, setTransitionSpacerHeight] = useState(false);
  // See the comment on freezeListContainerHeightIfNecessary()
  const [frozenListContainerHeight, setFrozenListContainerHeight] = useState<
    number | null
  >(null);
  const draggingElementHeight = useRef<number>(0);
  const listContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Synchronize any updates made in the outside world to the local groups/items
    setLocalGroupedItems(inputGroupedItems);
  }, [inputGroupedItems]);

  function efficientlySetGroupTargetIndex(newIndex: number | null) {
    if (newIndex !== groupTargetIndex) {
      setGroupTargetIndex(newIndex);
    }
  }

  function efficientlySetTransitionSpacerHeight(newVal: boolean) {
    if (newVal !== transitionSpacerHeight) {
      setTransitionSpacerHeight(newVal);
    }
  }

  function efficientlySetItemTargetIndices(newIndices: ItemIndices | null) {
    if (itemIndicesAreEqual(newIndices, itemTargetIndices)) {
      return;
    }
    if (newIndices === null) {
      setItemTargetIndices(null);
      return;
    }
    setItemTargetIndices({ ...newIndices });
  }

  function boundaryOnDragEnter(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    setGroupTargetIndex(null);
    setItemTargetIndices(null);
    e.dataTransfer.dropEffect = "move";
  }

  // When groups are large, we shrink the spacer placeholder to make it
  // less jarring/cumbersome to preview group re-ordering. However, this
  // can shrink the overall height of the list, which can automatically
  // scroll upward at the beginning of a drag. This is disorienting, so to
  // prevent this we "freeze" the height of the entire list right at the
  // beginning of a group drag.
  function freezeListContainerHeightIfNecessary() {
    if (
      frozenListContainerHeight === null &&
      groupDraggingIndex !== null &&
      transitionSpacerHeight &&
      listContainerRef.current
    ) {
      setFrozenListContainerHeight(
        listContainerRef.current.getBoundingClientRect().height
      );
    } else if (
      frozenListContainerHeight !== null &&
      groupDraggingIndex === null
    ) {
      setFrozenListContainerHeight(null);
    }
  }

  // Makes a copy and does not modify the input array.
  function arrayMove<T>(arr: T[], fromIndex: number, toIndex: number): T[] {
    let newArr = [...arr];
    var element = newArr[fromIndex];
    newArr.splice(fromIndex, 1);
    newArr.splice(toIndex, 0, element);
    return newArr;
  }

  // Makes a copy and does not modify the input array.
  function arrayAddAt<T>(arr: T[], insertionIndex: number, item: T): T[] {
    let newArr = [...arr];
    newArr.splice(insertionIndex, 0, item);
    return newArr;
  }

  // Makes a copy and does not modify the input array.
  function arrayRemoveFrom<T>(arr: T[], removalIndex: number): T[] {
    let newArr = [...arr];
    newArr.splice(removalIndex, 1);
    return newArr;
  }

  function updateOrder() {
    if (groupDraggingIndex !== null) {
      if (groupTargetIndex === null) {
        return;
      }

      setLocalGroupedItems((prevState) => {
        const newList = arrayMove(
          prevState,
          groupDraggingIndex,
          groupTargetIndex
        );
        onGroupOrderUpdate(
          newList.map((group) => group.id),
          prevState[groupDraggingIndex].id,
          groupDraggingIndex,
          groupTargetIndex
        );
        return newList;
      });

      return;
    }

    if (itemDraggingIndices !== null) {
      if (itemTargetIndices === null) {
        return;
      }

      if (itemDraggingIndices.groupIndex === itemTargetIndices.groupIndex) {
        setLocalGroupedItems((prevState) => {
          const newGroupedItems = [...prevState];
          const newGroup = { ...prevState[itemTargetIndices.groupIndex] };
          newGroup.items = arrayMove(
            prevState[itemTargetIndices.groupIndex].items,
            itemDraggingIndices.itemIndex,
            itemTargetIndices.itemIndex
          );
          newGroupedItems[itemTargetIndices.groupIndex] = newGroup;
          onItemOrderUpdate(
            newGroupedItems,
            prevState[itemDraggingIndices.groupIndex].items[
              itemDraggingIndices.itemIndex
            ].id,
            itemDraggingIndices,
            itemTargetIndices
          );
          return newGroupedItems;
        });

        return;
      }

      setLocalGroupedItems((prevState) => {
        const newGroupedItems = [...prevState];
        const newFromGroup = { ...prevState[itemDraggingIndices.groupIndex] };
        const newTargetGroup = { ...prevState[itemTargetIndices.groupIndex] };
        newFromGroup.items = arrayRemoveFrom(
          prevState[itemDraggingIndices.groupIndex].items,
          itemDraggingIndices.itemIndex
        );
        newTargetGroup.items = arrayAddAt(
          prevState[itemTargetIndices.groupIndex].items,
          itemTargetIndices.itemIndex,
          prevState[itemDraggingIndices.groupIndex].items[
            itemDraggingIndices.itemIndex
          ]
        );

        newGroupedItems[itemDraggingIndices.groupIndex] = newFromGroup;
        newGroupedItems[itemTargetIndices.groupIndex] = newTargetGroup;
        onItemOrderUpdate(
          newGroupedItems,
          prevState[itemDraggingIndices.groupIndex].items[
            itemDraggingIndices.itemIndex
          ].id,
          itemDraggingIndices,
          itemTargetIndices
        );
        console.log(newGroupedItems);
        return newGroupedItems;
      });
    }
  }

  let draggableGroupedList: JSX.Element[] = [];

  draggableGroupedList.push(
    <GroupSpacer
      key={`group spacer ${0}`}
      index={0}
      groupDraggingIndex={groupDraggingIndex}
      groupTargetIndex={groupTargetIndex}
      collapsedHeight={collapsedGroupSpacerHeight}
      transitionSpacerHeight={transitionSpacerHeight}
      draggingElementHeight={draggingElementHeight}
      setGroupTargetIndex={efficientlySetGroupTargetIndex}
      setTransitionSpacerHeight={efficientlySetTransitionSpacerHeight}
    />
  );

  localGroupedItems.forEach((group, index) => {
    draggableGroupedList.push(
      <Group
        key={group.id}
        group={group}
        index={index}
        groupDraggingIndex={groupDraggingIndex}
        itemDraggingIndices={itemDraggingIndices}
        setGroupDraggingIndex={setGroupDraggingIndex}
        setItemDraggingIndices={setItemDraggingIndices}
        groupColor={groupColor}
        itemColor={itemColor}
        groupTargetIndex={groupTargetIndex}
        itemTargetIndices={itemTargetIndices}
        collapsedItemSpacerHeight={collapsedItemSpacerHeight}
        transitionSpacerHeight={transitionSpacerHeight}
        draggingElementHeight={draggingElementHeight}
        setGroupTargetIndex={efficientlySetGroupTargetIndex}
        setItemTargetIndices={efficientlySetItemTargetIndices}
        showItemDividingLines={showItemDividingLines}
        setTransitionSpacerHeight={efficientlySetTransitionSpacerHeight}
      />
    );
    if (groupDraggingIndex !== index) {
      // When dragging to get the spacing right we remove the spacer
      // whose index is 1 greater than the dragging index.
      draggableGroupedList.push(
        <GroupSpacer
          key={`group spacer ${index + 1}`}
          index={
            groupDraggingIndex !== null && index > groupDraggingIndex
              ? index
              : index + 1
          }
          groupDraggingIndex={groupDraggingIndex}
          groupTargetIndex={groupTargetIndex}
          collapsedHeight={collapsedGroupSpacerHeight}
          transitionSpacerHeight={transitionSpacerHeight}
          draggingElementHeight={draggingElementHeight}
          setGroupTargetIndex={efficientlySetGroupTargetIndex}
          setTransitionSpacerHeight={efficientlySetTransitionSpacerHeight}
        />
      );
    }
  });

  freezeListContainerHeightIfNecessary();

  return (
    <div
      className={styles.boundaryContainer}
      style={{ backgroundColor: backgroundColor }}
    >
      <div className={styles.leftBoundary} onDragEnter={boundaryOnDragEnter} />
      <div className={styles.middleContainer}>
        <div className={styles.topBoundary} onDragEnter={boundaryOnDragEnter} />
        <div
          ref={listContainerRef}
          onDragEnter={(e) => {
            e.preventDefault();
            e.dataTransfer.dropEffect = "move";
          }}
          onDragOver={(e) => {
            e.preventDefault();
            e.dataTransfer.dropEffect = "move";
          }}
          onDrop={(e) => {
            e.stopPropagation();
            updateOrder();
            setTransitionSpacerHeight(false);
            setGroupDraggingIndex(null);
            setItemDraggingIndices(null);
            setGroupTargetIndex(null);
            setItemTargetIndices(null);
            setTransitionSpacerHeight(false);
          }}
          className={styles.listContainer}
          style={
            frozenListContainerHeight !== null
              ? { height: frozenListContainerHeight }
              : {}
          }
        >
          {draggableGroupedList}
        </div>
        <div
          className={styles.bottomBoundary}
          onDragEnter={boundaryOnDragEnter}
        />
      </div>
      <div className={styles.rightBoundary} onDragEnter={boundaryOnDragEnter} />
    </div>
  );
}
