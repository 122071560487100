import { DefaultTransactionColumnHeader } from "./DefaultTransactionColumnHeader";
import { TransactionColumn } from "./TransactionColumn";
import { TransactionColumnSizer } from "./TransactionColumnSizer";

export function TransactionColumnHeaderAccount(): JSX.Element {
  return (
    <TransactionColumnSizer column={TransactionColumn.ACCOUNT}>
      <DefaultTransactionColumnHeader
        column={TransactionColumn.ACCOUNT}
        text={"Account"}
      />
    </TransactionColumnSizer>
  );
}
