import { buildClasses } from "../utils/buildClasses";
import { TRANSACTION_CELL_PADDING_X } from "./TransactionColumnSizer";

export function DefaultTransactionColumnValue({
  align = "LEFT",
  ruleHighlight = false,
  children,
}: {
  ruleHighlight?: boolean;
  align?: "LEFT" | "RIGHT";
  children?: JSX.Element | string | null;
}) {
  return (
    <div
      className={buildClasses(
        {
          if: ruleHighlight,
          then: buildClasses(
            "border-purple",
            "shadow-glow",
            "shadow-purple/20"
          ),
          else: buildClasses("border-transparent", "shadow-none"),
        },
        {
          switch: align,
          cases: new Map([
            ["LEFT", "justify-start"],
            ["RIGHT", "justify-end"],
          ]),
        },
        "w-full",
        "h-full",
        "flex",
        "items-center",
        "rounded-lg",
        "border-tiny",
        TRANSACTION_CELL_PADDING_X
      )}
    >
      {children}
    </div>
  );
}
