import { Fragment, useContext } from "react";
import { RulesContext } from "../firebaseio/RulesContext";
import { getNullFieldValueCriterionEquivalent } from "breadcommon";
import { CategoriesContext } from "../firebaseio/CategoriesContext";
import { FirestoreDocRule } from "breadcommon";
import { VerticalSpacer } from "../utils/Utils";
import {
  firestoreDeleteRuleAndUpdateTransactions,
  firestoreUpdateRulesOrderAndUpdateTransactions,
} from "../firebaseio/firestoreIo";
import { UserContext } from "../firebaseio/UserContext";
import { InlineAccount } from "../common/InlineAccount";
import { RyeMainContentCard } from "../rye/RyeMainContentCard";
import { buildClasses } from "../utils/buildClasses";
import { RyeIcon } from "../rye/RyeIcon";
import { RyeButton } from "../rye/RyeButton";
import { RyeDraggableList } from "../rye/RyeDraggableList";
import { RyeMainContentTitle } from "../rye/RyeMainContentTitle";

export function RulesScreen(): JSX.Element {
  const rules = useContext(RulesContext);
  const user = useContext(UserContext);

  const rulesList = Array.from(rules.values())
    .sort((a, b) => a.order_position - b.order_position)
    .map((rule, index) => {
      return {
        id: rule.id,
        value: (
          <Fragment>
            <div
              className={buildClasses("w-full", "rounded-lg", "p-7")}
              key={rule.id}
            >
              <RulePreview rule={rule} />
              <VerticalSpacer height={8} />
              <div
                className={buildClasses(
                  "flex",
                  "items-center",
                  "justify-between"
                )}
              >
                <div>Priority: {index + 1}</div>
                <RyeButton
                  icon="delete"
                  variant="transparent"
                  vibe="subdued"
                  size="sm"
                  onClick={() =>
                    firestoreDeleteRuleAndUpdateTransactions(user.uid, rule.id)
                  }
                ></RyeButton>
              </div>
            </div>
            {index === rules.size ? <VerticalSpacer height={10} /> : null}
          </Fragment>
        ),
      };
    });

  return (
    <RyeMainContentCard>
      <div className={buildClasses("p-7")}>
        <div className={buildClasses("px-4", "pb-4")}>
          <RyeMainContentTitle title={"Rules"} />
        </div>
        <div
          className={buildClasses(
            "p-6",
            "mt-4",
            "mx-4",
            "bg-surface-darkerr",
            "rounded-lg"
          )}
        >
          <div
            className={buildClasses(
              "flex",
              "items-center",
              "pb-2",
              "font-bold"
            )}
          >
            <RyeIcon name="lightbulb_2" />
            <div className="w-3" />
            <div>How rules work:</div>
          </div>
          <ul
            className={buildClasses(
              "list-disc",
              "list-inside",
              "pl-9",
              "text-sm"
            )}
          >
            <li>
              A Rule matches a Transaction if the Transaction meets the criteria
              of the Rule.
            </li>
            <li>
              If more than one Rule matches a transaction, the rule with the
              highest priority is used (this page is sorted in priority from
              highest to lowest).
            </li>
            <li>
              Whenever a Rule is added, deleted, or modified, we reapply all
              rules to all Unreviewed transactions to find the best match.
            </li>
            <li>
              If a Transaction is already marked as Reviewed, then adding,
              deleting, or updating a Rule will not impact that Transaction.
            </li>
          </ul>
        </div>
        <VerticalSpacer height={15} />
        <RyeDraggableList
          inputItems={rulesList}
          showDividingLines={false}
          backgroundColor="transparent"
          onOrderUpdate={function (newIdList: string[]): void {
            firestoreUpdateRulesOrderAndUpdateTransactions(user.uid, newIdList);
          }}
        />
      </div>
    </RyeMainContentCard>
  );
}

export function RulePreview(props: { rule: FirestoreDocRule }): JSX.Element {
  const categories = useContext(CategoriesContext);
  const rule = props.rule;
  const category = categories.get(rule.action.category_id ?? "");
  const categoryString =
    `${category?.emoji} ${category?.name}` ??
    rule.action.category_id ??
    "MISSING CATEGORY_ID";
  const isEmpty = (criterion_value: string): boolean => {
    return criterion_value === getNullFieldValueCriterionEquivalent();
  };
  const criteria = rule.criteria;
  return (
    <div>
      Assign the category <b>{categoryString}</b> if all of the following are
      true for a Transaction:
      <ul className={buildClasses("list-disc", "list-inside")}>
        {criteria.account_id && (
          <li key="account_id">
            <div className={buildClasses("inline-flex", "items-center")}>
              <b>Account</b>
              <div className="w-2" />
              is
              <div className="w-2" />
              {isEmpty(criteria.account_id) ? (
                "empty"
              ) : (
                <InlineAccount account_id={criteria.account_id} logoGap={10} />
              )}
            </div>
          </li>
        )}
        {criteria.merchant_name && (
          <li key="merchant_name">
            <b>Merchant</b> is{" "}
            {isEmpty(criteria.merchant_name)
              ? "empty"
              : `exactly "${criteria.merchant_name}"`}
          </li>
        )}
        {criteria.type && (
          <li key="type">
            <b>Type</b> is {isEmpty(criteria.type) ? "empty" : criteria.type}
          </li>
        )}
        {criteria.subtype && (
          <li key="subtype">
            <b>Subtype</b> is{" "}
            {isEmpty(criteria.subtype) ? "empty" : criteria.subtype}
          </li>
        )}
        {criteria.description && (
          <li key="description">
            <b>Description</b> is{" "}
            {isEmpty(criteria.description)
              ? "empty"
              : `exactly "${criteria.description}"`}
          </li>
        )}
        {criteria.partial_description && (
          <li key="partial_description">
            <b>Description</b> contains "{criteria.partial_description}"
          </li>
        )}
      </ul>
    </div>
  );
}
