import dayjs from "dayjs";
import { DefaultTransactionColumnValue } from "./DefaultTransactionColumnValue";
import { TransactionColumnSizer } from "./TransactionColumnSizer";
import { TransactionColumn } from "./TransactionColumn";

export function TransactionColumnValueDate({
  date,
}: {
  date: dayjs.Dayjs;
}): JSX.Element {
  return (
    <TransactionColumnSizer column={TransactionColumn.DATE}>
      <DefaultTransactionColumnValue>
        {date.format("MMM D, YYYY")}
      </DefaultTransactionColumnValue>
    </TransactionColumnSizer>
  );
}
