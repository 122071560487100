import { DefaultTransactionColumnHeader } from "./DefaultTransactionColumnHeader";
import { TransactionColumn } from "./TransactionColumn";
import { TransactionColumnSizer } from "./TransactionColumnSizer";

export function TransactionColumnHeaderAmount(): JSX.Element {
  return (
    <TransactionColumnSizer column={TransactionColumn.AMOUNT}>
      <DefaultTransactionColumnHeader
        column={TransactionColumn.AMOUNT}
        text={"Amount"}
        align="RIGHT"
      />
    </TransactionColumnSizer>
  );
}
