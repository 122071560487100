import EmojiPicker from "emoji-picker-react";
import { useState, useRef } from "react";
import { KeyboardEvent } from "react";
import { useClickOutsideElement } from "../utils/customHooks";
import { RyeIcon } from "./RyeIcon";
import { buildClasses } from "../utils/buildClasses";
import "./RyeEmojiPicker.css";
import { keyCodes } from "../utils/KeyCodes";

export function RyeEmojiPicker({
  initialEmoji = "",
  save,
}: {
  initialEmoji?: string;
  save: (newEmoji: string) => void;
}): JSX.Element {
  const [selectedEmoji, setSelectedEmoji] = useState<string>(initialEmoji);
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const emojiAndPickerRef = useRef<HTMLDivElement>(null);

  useClickOutsideElement(
    emojiAndPickerRef,
    () => setShowEmojiPicker(false),
    showEmojiPicker,
    window
  );

  function handleKeyUp(e: KeyboardEvent<HTMLInputElement>): void {
    if (showEmojiPicker && e.code === keyCodes.ESC) {
      e.stopPropagation();
      setShowEmojiPicker(false);
    }
  }

  return (
    <div
      ref={emojiAndPickerRef}
      className={buildClasses(
        {
          if: showEmojiPicker,
          then: buildClasses(
            "border-purple",
            "shadow-glow",
            "shadow-purple-100"
          ),
          else: buildClasses("border-transparent", "hover:border-rock-200"),
        },
        "cursor-pointer",
        "relative",
        "px-2",
        "py-0.5",
        "rounded-md",
        "border"
      )}
      onClick={() => setShowEmojiPicker(!showEmojiPicker)}
      onKeyUp={handleKeyUp}
    >
      <div>
        {selectedEmoji !== "" ? (
          selectedEmoji
        ) : (
          <RyeIcon name="add_reaction" size={"sm"}></RyeIcon>
        )}
      </div>
      {showEmojiPicker ? (
        <EmojiPicker
          onEmojiClick={(emoji) => {
            setSelectedEmoji(emoji.emoji);
            setShowEmojiPicker(false);
            save(emoji.emoji);
          }}
          height={350}
          width={350}
          previewConfig={{ showPreview: false }}
        ></EmojiPicker>
      ) : null}
    </div>
  );
}
