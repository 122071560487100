import { useEffect, useRef, useState } from "react";
import { HorizontalSpacer } from "../utils/Utils";
import { buildClasses } from "../utils/buildClasses";
import { RyeIcon } from "./RyeIcon";

function RyeDraggableListItem(props: {
  item: { id: string; value: JSX.Element };
  index: number;
  transitionSpacerHeight: React.MutableRefObject<boolean>;
  draggingElementHeight: React.MutableRefObject<number>;
  draggingIndex: number | null;
  setDraggingIndex: React.Dispatch<React.SetStateAction<number | null>>;
  setTargetIndex: (newIndex: number | null) => void;
  itemColor: string;
}): JSX.Element {
  const [draggable, setDraggable] = useState(false);
  const [dragging, setDragging] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={divRef}
      draggable={draggable}
      className={buildClasses(
        {
          if: dragging,
          then: buildClasses("hidden", "invisible", "h-0"),
          else: buildClasses("flex", "visible"),
        },
        "flex",
        "items-center",
        "px-5",
        "rounded-lg",
        "translate-x-0" // necessary hack for getting border radius to work on draggable element while dragging - see https://github.com/react-dnd/react-dnd/issues/788#issuecomment-367300464
      )}
      // className={`${styles.draggableListItem} ${
      //   dragging ? styles.dragging : ""
      // }`}
      style={{ backgroundColor: props.itemColor }}
      onDrag={() => {
        if (!dragging) {
          setDragging(true);
          props.setDraggingIndex(props.index);
          props.transitionSpacerHeight.current = false;
          props.setTargetIndex(props.index);
          props.draggingElementHeight.current =
            divRef.current?.getBoundingClientRect().height ?? 0;
        }
      }}
      onDragEnd={() => {
        setDragging(false);
        setDraggable(false);
        props.setDraggingIndex(null);
        props.setTargetIndex(null);
        props.transitionSpacerHeight.current = false;
      }}
      onDragOver={(e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";

        if (props.draggingIndex === null || divRef.current === null) {
          return;
        }

        const mousePositionY = e.clientY;
        const itemCenterPositionY =
          divRef.current.getBoundingClientRect().top +
          divRef.current.getBoundingClientRect().height / 2;

        if (
          mousePositionY < itemCenterPositionY &&
          props.draggingIndex < props.index
        ) {
          props.setTargetIndex(props.index - 1);
        }
        if (
          mousePositionY < itemCenterPositionY &&
          props.draggingIndex > props.index
        ) {
          props.setTargetIndex(props.index);
        }
        if (
          mousePositionY > itemCenterPositionY &&
          props.draggingIndex < props.index
        ) {
          props.setTargetIndex(props.index);
        }
        if (
          mousePositionY > itemCenterPositionY &&
          props.draggingIndex > props.index
        ) {
          props.setTargetIndex(props.index + 1);
        }
      }}
    >
      <div
        className={buildClasses("flex", "items-center")}
        onMouseEnter={() => setDraggable(true)}
        onMouseLeave={() => setDraggable(false)}
      >
        <RyeIcon
          name={"drag_indicator"}
          size={"md"}
          className={buildClasses("cursor-move")}
        />
      </div>
      <HorizontalSpacer width={20} />
      {props.item.value}
    </div>
  );
}

function Spacer(props: {
  index: number;
  targetIndex: number | null;
  collapsedHeight: number;
  transitionSpacerHeight: React.MutableRefObject<boolean>;
  draggingElementHeight: React.MutableRefObject<number>;
  setTargetIndex: (newIndex: number | null) => void;
  showDividingLines: boolean;
  isFirstOrLastSpacer: boolean;
}): JSX.Element {
  function isExpanded() {
    return props.index === props.targetIndex;
  }

  return (
    <div
      style={{
        height: isExpanded()
          ? props.draggingElementHeight.current - 10
          : props.collapsedHeight,
        marginTop: isExpanded() ? props.collapsedHeight + 5 : 0,
        marginBottom: isExpanded() ? props.collapsedHeight + 5 : 0,
        marginLeft: isExpanded() ? 5 : 0,
        marginRight: isExpanded() ? 5 : 0,
      }}
      className={buildClasses(
        {
          if: isExpanded(),
          then: buildClasses(
            "bg-surface-darker",
            "shadow-draggable-list-target",
            "shadow-surface-darker"
          ),
          else: buildClasses("bg-transparent"),
        },
        {
          if: props.transitionSpacerHeight.current,
          then: buildClasses("transition-height", "duration-200"),
        },
        "flex",
        "items-center",
        "justify-center",
        "rounded-lg"
      )}
      onDragEnter={(e) => {
        e.preventDefault();
        props.setTargetIndex(props.index);
        e.dataTransfer.dropEffect = "move";
      }}
      onDragOver={(e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";
        props.transitionSpacerHeight.current = true;
      }}
      onDragLeave={() => {
        props.transitionSpacerHeight.current = true;
      }}
    >
      {props.showDividingLines &&
      !props.isFirstOrLastSpacer &&
      !isExpanded() ? (
        <div
          className={buildClasses("w-full", "h-px", "bg-surface-darkerr")}
        ></div>
      ) : null}
    </div>
  );
}

// NOTE: We do the re-ordering in the onDrop event instead of
// the item being dragged's onDragEnd event because onDragEnd doesn't fire
// until the dragged item is actually back where it belongs, not right when
// the dragged item is released by the mouse. We want to re-order things
// right when the mouse is released so the animation of the dragged item
// jumps into it's *new* position when released, not its old one.
// A side effect of this is we can't detect onDrop when the mouse is
// hovering over anything outside of the list (i.e. if you drag too far
// above/below/left/right of the list). Therefore, we add boundaries on
// every side of the list to detect this and indicate this to the user by
// collapsing the spacer that was open.
export function RyeDraggableList({
  inputItems,
  onOrderUpdate,
  collapsedSpacerHeight = 10,
  showDividingLines = true,
  backgroundColor = "#FFFFFF",
  itemColor = "#FFFFFF",
}: {
  inputItems: { id: string; value: JSX.Element }[];
  onOrderUpdate: (
    newIdList: string[],
    id: string,
    oldIndex: number,
    newIndex: number
  ) => void;
  collapsedSpacerHeight?: number;
  showDividingLines?: boolean;
  backgroundColor?: string;
  itemColor?: string;
}): JSX.Element {
  const [localItems, setLocalItems] =
    useState<{ id: string; value: JSX.Element }[]>(inputItems);
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
  const [targetIndex, setTargetIndex] = useState<number | null>(null);
  const transitionSpacerHeight = useRef(false);
  const draggingElementHeight = useRef<number>(0);

  useEffect(() => {
    // Synchronize any updates made in the outside world to the local items
    setLocalItems(inputItems);
  }, [inputItems]);

  function efficientlySetTargetIndex(newIndex: number | null) {
    if (newIndex !== targetIndex) {
      setTargetIndex(newIndex);
    }
  }

  function boundaryOnDragEnter(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    setTargetIndex(null);
    e.dataTransfer.dropEffect = "move";
  }

  function arraymove(
    arr: { id: string; value: JSX.Element }[],
    fromIndex: number,
    toIndex: number
  ) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr;
  }

  function updateOrder() {
    if (draggingIndex == null || targetIndex == null) {
      return;
    }

    setLocalItems((prevState) => {
      const newList = [
        ...arraymove([...prevState], draggingIndex, targetIndex),
      ];
      onOrderUpdate(
        newList.map((item) => item.id),
        localItems[draggingIndex].id,
        draggingIndex,
        targetIndex
      );
      return newList;
    });
  }

  let draggableList: JSX.Element[] = [];
  draggableList.push(
    <Spacer
      key={"spacer 0"}
      index={0}
      targetIndex={targetIndex}
      collapsedHeight={collapsedSpacerHeight}
      transitionSpacerHeight={transitionSpacerHeight}
      draggingElementHeight={draggingElementHeight}
      setTargetIndex={efficientlySetTargetIndex}
      showDividingLines={showDividingLines}
      isFirstOrLastSpacer={true}
    />
  );
  localItems.forEach((value, index) => {
    draggableList.push(
      <RyeDraggableListItem
        key={value.id}
        index={index}
        item={value}
        transitionSpacerHeight={transitionSpacerHeight}
        draggingElementHeight={draggingElementHeight}
        draggingIndex={draggingIndex}
        setDraggingIndex={setDraggingIndex}
        setTargetIndex={efficientlySetTargetIndex}
        itemColor={itemColor}
      />
    );
    if (draggingIndex !== index) {
      // When dragging to get the spacing right we remove the spacer
      // whose index is 1 greater than the dragging index.
      draggableList.push(
        <Spacer
          key={`spacer ${index + 1}`}
          index={
            draggingIndex !== null && index > draggingIndex ? index : index + 1
          }
          targetIndex={targetIndex}
          collapsedHeight={collapsedSpacerHeight}
          transitionSpacerHeight={transitionSpacerHeight}
          draggingElementHeight={draggingElementHeight}
          setTargetIndex={efficientlySetTargetIndex}
          showDividingLines={showDividingLines}
          isFirstOrLastSpacer={
            draggingIndex === localItems.length - 1
              ? index + 2 === localItems.length
              : index + 1 === localItems.length
          }
        />
      );
    }
  });

  return (
    <div
      className={buildClasses("flex", "items-stretch")}
      style={{ backgroundColor: backgroundColor }}
    >
      <div
        // Left boundary
        className={buildClasses("w-4")}
        onDragEnter={boundaryOnDragEnter}
      />
      <div
        // Middle container
        className={buildClasses("flex-grow")}
      >
        <div
          // Top boundary
          className={buildClasses("w-full", "h-4")}
          onDragEnter={boundaryOnDragEnter}
        />
        <div
          // List container
          className={buildClasses("overflow-hidden")}
          onDragEnter={(e) => {
            e.preventDefault();
            e.dataTransfer.dropEffect = "move";
          }}
          onDragOver={(e) => {
            e.preventDefault();
            e.dataTransfer.dropEffect = "move";
          }}
          onDrop={(e) => {
            e.stopPropagation();
            updateOrder();
            transitionSpacerHeight.current = false;
          }}
        >
          {draggableList}
        </div>
        <div
          // Bottom boundary
          className={buildClasses("w-full", "h-4")}
          onDragEnter={boundaryOnDragEnter}
        />
      </div>
      <div
        // Right boundary
        className={buildClasses("w-4")}
        onDragEnter={boundaryOnDragEnter}
      />
    </div>
  );
}
