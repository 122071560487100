import { Fragment, useState } from "react";
import { RyeButton } from "../rye/RyeButton";
import {
  RyeMainContentCard,
  STANDARD_MAIN_CONTENT_CARD_PADDING_X,
} from "../rye/RyeMainContentCard";
import { RyeMainContentHeader } from "../rye/RyeMainContentHeader";
import { RyeMainContentSpacer } from "../rye/RyeMainContentSpacer";
import { buildClasses } from "../utils/buildClasses";
import { AddAccountModal } from "./AddAccountModal";
import { AccountsList } from "./AccountsList";
import { Balances } from "./Balances";
import { RyeMainContentTitle } from "../rye/RyeMainContentTitle";

export function AccountsScreen(): JSX.Element {
  const [addAccountModalIsOpen, setAddAccountModalIsOpen] =
    useState<boolean>(false);

  return (
    <div
      className={buildClasses(
        "w-full",
        "h-full",
        "flex",
        "flex-col",
        "flex-grow",
        "flex-shrink",
        "min-w-1"
      )}
    >
      <RyeMainContentHeader>
        <Fragment>
          <RyeButton
            text="Add Account"
            icon="add"
            size="sm"
            variant="outlined"
            vibe="subdued"
            onClick={() => setAddAccountModalIsOpen(true)}
          />
          {addAccountModalIsOpen ? (
            <AddAccountModal
              isOpen={addAccountModalIsOpen}
              setIsOpen={setAddAccountModalIsOpen}
            ></AddAccountModal>
          ) : null}
        </Fragment>
      </RyeMainContentHeader>
      <RyeMainContentSpacer direction={"h"} />
      <div className={buildClasses("flex", "flex-grow")}>
        <RyeMainContentCard
          roundedTl={false}
          roundedTr={false}
          roundedBr={false}
        >
          <div className={buildClasses("px-6", "min-w-[840px]")}>
            <div className={buildClasses("px-10", "pt-10", "pb-2")}>
              <RyeMainContentTitle title={"Accounts"} />
            </div>
            <AccountsList />
          </div>
        </RyeMainContentCard>
        <RyeMainContentSpacer direction={"v"} />
        <RyeMainContentCard
          flexGrow={false}
          flexShrink={false}
          roundedTl={false}
          roundedTr={false}
          roundedBl={false}
          width="w-auto"
          height="h-full"
        >
          <div className={buildClasses(STANDARD_MAIN_CONTENT_CARD_PADDING_X)}>
            <div className={buildClasses("pt-10", "pb-10")}>
              <RyeMainContentTitle title={"Totals"} />
            </div>
            <Balances />
          </div>
        </RyeMainContentCard>
      </div>
    </div>
  );
}
