/* Assists with joining multiple CSS classes together */
export function buildClasses<T>(
  ...args: (
    | string
    | {
        if: boolean;
        then: string;
        elseIfs?: { elseIf: boolean; then: string }[];
        else?: string;
      }
    | {
        switch: T;
        cases: Map<T, string>;
        default?: string;
      }
  )[]
) {
  let classes = "";
  for (const arg of args) {
    if (typeof arg === "string") {
      classes += " " + arg;
    } else if ("if" in arg) {
      let conditionMet = false;
      if (arg.if) {
        classes += " " + arg.then;
        conditionMet = true;
      } else if ("elseIfs" in arg && arg.elseIfs !== undefined) {
        for (const elseIf of arg.elseIfs) {
          if (elseIf.elseIf) {
            classes += " " + elseIf.then;
            conditionMet = true;
            break;
          }
        }
      }
      if (!conditionMet && "else" in arg && arg.else !== undefined) {
        classes += " " + arg.else;
      }
    } else if ("switch" in arg && arg.cases.has(arg.switch)) {
      classes += " " + arg.cases.get(arg.switch);
    } else if ("switch" in arg && "default" in arg) {
      classes += " " + arg.default;
    }
  }
  return classes;
}
